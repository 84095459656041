<template>
    <div>
        <label class="flex flex-col w-full justify-center">
            <span v-if="label" class="block pb-2 font-bold uppercase tracking-wide text-sm">{{ label }}</span>
            <select @change="selectOption($event)"
                    :disabled="disabled"
                    class="block py-2 px-2 w-full placeholder-gray-dark text-lg outline-none
                transition-all duration-300 disabled:bg-gray border border-gray-dark border-opacity-50 focus:border-blue
                 focus:ring-4 focus:ring-blue focus:ring-opacity-50 bg-white text-[15px] h-[50px]"
                    :class="{
                    [classes]: true,
                    'border-green': valid && showValidity,
                    'border-red': !valid && showValidity
                }">
                <option v-if="placeholderEnable"
                        disabled
                        :selected="modelValue === null || modelValue === ''">
                    {{placeholder}}
                </option>
                <option v-for="option of options"
                        :key="option.value"
                        :selected="option.selected"
                        :value="option.value">
                    {{option.text}}
                </option>
            </select>
        </label>
        <div v-if="showValidity && invalidMessage !== '' && !valid" class="text-red mt-2 text-sm">
            {{invalidMessage}}
        </div>
    </div>
</template>

<script setup lang="ts">
import { type PropType} from "vue";
import Option from "./Option";

const props = defineProps({
    label: {
        type: String,
        required: false,
        default: "",
    },
    options: {
        type: Array as PropType<Array<Option>>,
        required: true,
        default: () => []
    },
    modelValue: [Number, String, () => null],
    placeholderEnable: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    classes: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    showValidity: {
        type: Boolean,
        default: false,
    },
    valid: {
        type: Boolean,
        default: true,
        required: false
    },
    invalidMessage: {
        type: String,
        required: false,
        default: ''
    }
});

const emit = defineEmits(["update:modelValue"]);

const selectOption: any = (event: any) => {
    emit('update:modelValue', event.target.value);
}
</script>
