import {defineStore} from "pinia";
import fetch from "../../common/utils/fetch";
import type ShopResponseType from "../../common/utils/ShopResponseType";
import {type Router} from "vue-router";
import type PermissionStateInterface from "./PermissionState";
import {type PermissionInterface} from "../../models/Permission";
import type PermissionQuery from "./PermissionQuery";

export const usePermissionStore = defineStore('permissionStore', {
    state: (): PermissionStateInterface => ({
        loading: false,
        permissions: []
    }),
    getters: {
        permissionGroupped: (state: PermissionStateInterface): Record<string, PermissionInterface[]> => {
            const permissions: PermissionInterface[] = state.permissions;
            const permissionsGroupped: Record<string, PermissionInterface[]> = {};

            permissions.forEach((permission: PermissionInterface) => {
                const group = String(permission.group).toString();
                if (!permissionsGroupped[group]) {
                    permissionsGroupped[group] = [];
                }

                permissionsGroupped[group].push(permission);
            });

            return permissionsGroupped;
        }
    },
    actions: {
        getPermissions(router: Router, query: PermissionQuery): Promise<ShopResponseType> {
            this.loading = true;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'permissions_api', query: query }).href)
                    .then((response: ShopResponseType) => {
                        const permissions: PermissionInterface[] = [];

                        if (Array.isArray(response['hydra:member'])) {
                            response['hydra:member'].forEach(element => {
                                if (element['@type'] !== 'Permission') {
                                    return;
                                }
                                permissions.push(element);
                            });
                        }

                        this.permissions = permissions;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch(error => {
                        this.loading = false;
                        reject(error);
                    });
            });
        }
    },
})
