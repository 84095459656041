<template>
    <router-link
        v-if="to"
        :to="to"
        class="px-2 py-2 hover:bg-gray"
        :class="{ 'cursor-pointer': pointer }"
        @click="onSelect"
    >
        <slot></slot>
    </router-link>
    <div
        v-else
        class="px-2 py-2"
        :class="{ 'cursor-pointer': pointer, 'hover:bg-gray': pointer }"
        @click="onSelect"
    >
        <slot></slot>
    </div>
</template>

<script setup lang="ts">

const emit = defineEmits(['selected']);
const props = defineProps({
    to: {
        type: [String, Object],
    },
    pointer: {
        type: Boolean,
        default: false
    }
});


const onSelect = () => {
    emit('selected');
};
</script>
