import type ResponseData from "./ResponseData";

export default class ServerError extends Error {
    public readonly response: Response;
    public readonly responseData: ResponseData

    constructor(
        message: string,
        response: Response,
        responseData: ResponseData
    ) {
        super(message);
        this.response = response;
        this.responseData = responseData;
    }

    public getStatusCode(): number {
        return this.response.status;
    }

    public toString(): string {
        return this.responseData['hydra:description'];
    }
}
