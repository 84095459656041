<template>
    <div :id="`dropdown_${componentId}`"
         class="relative dropdown hoverable"
         :class="{
        active: activeMenu,
        'mobile-full-width': mobileFullWidth
    }"
         ref="dropdown">
        <span class="h-full flex items-center justify-start gap-2 cursor-pointer" @click="toggleMenu()">
            <div v-if="buttonText.length"
                 class="flex grow flex-col items-start justify-center content-center whitespace-nowrap overflow-hidden">
                <div>{{ buttonText }}</div>
            </div>
            <slot v-else name="buttonText"></slot>
            <i v-if="!hideCaret"
               class="marso-icon-chevron-down transform-gpu transition-transform text-xs"
               aria-hidden="true"></i>
        </span>
        <div class="dropdown-menu rounded-b-md"
            :class="{
                '-ml-2': !right,
                'right-0': right
            }">
            <slot :itemSelected="itemSelected"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, onUnmounted, type Ref, ref} from "vue";

const emit = defineEmits(['selected']);

const props = defineProps({
    buttonText: {
        type: String,
        default: ''
    },
    right: {
        type: Boolean,
        default: false
    },
    hideCaret: {
        type: Boolean,
        default: false
    },
    mobileFullWidth: {
        type: Boolean,
        default: false
    },
    clickable: {
        type: Boolean,
        default: false
    }
});

const activeMenu: Ref<boolean> = ref(false);
const dropdown: Ref<HTMLElement | null> = ref(null);
const componentId = getCurrentInstance()?.uid;

const clickOutsideListener = (event: MouseEvent) => {
    if (dropdown.value !== null && !dropdown.value.contains(event.target as HTMLElement)) {
        close();
    }
};

onMounted(() => {
    dropdown.value = document.getElementById(`dropdown_${componentId}`);
    document.addEventListener('click', clickOutsideListener);
});

onUnmounted(() => {
    document.removeEventListener('click', clickOutsideListener);
});

const close = () => {
    activeMenu.value = false;
}

const itemSelected = () => {
    close();
    emit('selected');
};

const toggleMenu = () => {
    if(props.clickable) {
        activeMenu.value = !activeMenu.value;
    }
};

defineExpose({close})
</script>

<style lang="scss">
@mixin hover-supported {
    /*
     * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer
     * coarse: The primary input mechanism includes a pointing device of limited accuracy.
     */
    @media not all and (pointer: coarse) {
        &:hover {
            @content;
        }
    }
}

.dropdown {
    > .dropdown-menu {
        // @apply fixed left-0 w-full lg:absolute lg:left-auto lg:min-w-full lg:w-max flex flex-col whitespace-nowrap invisible;
        @apply absolute left-auto min-w-full w-max flex flex-col whitespace-nowrap hidden;
        @apply transform-gpu transition-all duration-300 ease-out opacity-0 -translate-y-8 -translate-x-4 scale-75;
        @apply font-normal text-base normal-case bg-white text-gray-darkest justify-start py-2 shadow z-50;

        &.right-0 {
            @apply translate-x-4;
        }
    }

    &.mobile-full-width {
        > .dropdown-menu {
            @apply fixed;
        }
    }

    .dropdown {
        > span {
            @apply p-2 lg:p-0;
        }

        > .dropdown-menu {
            @apply lg:px-0;
        }
    }

    &.disabled::after {
        @apply block absolute left-0 top-0 w-full h-full bg-white opacity-70;

        content: " ";
    }

    &.hoverable:not(.disabled) {
        @include hover-supported() {
            @screen lg {
                &:hover {
                    > span {
                        .marso-icon-chevron-down {
                            @apply rotate-180;
                        }
                    }

                    > .dropdown-menu {
                        @apply flex opacity-100 scale-100 translate-y-0 translate-x-0;
                    }

                    .dropdown {
                        &:hover {
                            > .dropdown-menu {
                                @apply relative shadow-inner;
                            }
                        }
                    }
                }
            }
        }
    }

    &.active {
        &:not(.hoverable) {
            @apply border-blue ring-4 ring-blue ring-opacity-50;
        }

        > span {
            .marso-icon-chevron-down {
                @apply rotate-180;
            }
        }

        > .dropdown-menu {
            @apply flex opacity-100 scale-100 translate-y-0 translate-x-0;
        }

        .dropdown {
            &.active {
                > .dropdown-menu {
                    @apply relative shadow-inner;
                }
            }
        }
    }
}
</style>
