// import './assets/main.scss';
import './styles/app.scss';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from "@sentry/vue";

import App from './pages/App.vue';
import router from './routes';
import TranslatorObject from './common/i18n';
import FetchComponent from './common/utils/FetchComponent';

// https://vite-pwa-org.netlify.app/guide/auto-update.html#automatic-reload
import { registerSW } from 'virtual:pwa-register';
registerSW({ immediate: true })

const app = createApp(App);

Sentry.init({
    app,
    dsn: 'https://e6fdb548a3fc7a01d0c467253e74bacf@o310262.ingest.sentry.io/4505986555248640'
});

app
    .use(TranslatorObject)
    .use(router)
    .use(FetchComponent)
    .use(createPinia());

app.mount('#app');

export default app;
