import ServerError from "./ServerError";
import type ResponseData from "./ResponseData";

export default class ForbiddenServerError extends ServerError {
    public readonly redirect: string;

    constructor(
        response: Response,
        responseData: ResponseData,
        redirect: string
    ) {
        super('ForbiddenServerError', response, responseData);
        this.redirect = redirect;
    }
}
