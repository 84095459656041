import ServerError from "./ServerError";
import type ResponseData from "./ResponseData";

export default class UnauthorizedServerError extends ServerError {
    constructor(
        response: Response,
        responseData: ResponseData
    ) {
        super('UnauthorizedServerError', response, responseData);
    }

    public toString(): string {
        return this.responseData.message;
    }
}
