import {defineStore} from "pinia";
import fetch from "../../common/utils/fetch";
import type ShopResponseType from "../../common/utils/ShopResponseType";
import {type Router} from "vue-router";
import type UserGroupStateInterface from "./UserGroupState";
import {type UserGroupInterface} from "../../models/UserGroup";
import type UserGroupQuery from "./UserGroupQuery";
import UserGroupRequest from "./UserGroupRequest";

export const useUserGroupStore = defineStore('userGroupStore', {
    state: (): UserGroupStateInterface => ({
        loading: false,
        userGroups: []
    }),
    getters: {
    },
    actions: {
        getUserGroups(router: Router, query: UserGroupQuery = {}): Promise<ShopResponseType> {
            this.loading = true;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'admin_user_groups_api', query: query }).href)
                    .then((response: ShopResponseType) => {
                        const userGroups: UserGroupInterface[] = [];

                        if (Array.isArray(response['hydra:member'])) {
                            response['hydra:member'].forEach(element => {
                                if (element['@type'] !== 'UserGroup') {
                                    return;
                                }
                                userGroups.push(element);
                            });
                        }

                        this.userGroups = userGroups;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch(error => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },
        createUserGroup(router: Router, userRequest: UserGroupRequest) {
            this.loading = true;

            let options = { body: JSON.stringify(userRequest), method: 'POST' };

            return new Promise((resolve, reject) => {
                fetch(router.resolve({name: 'admin_user_groups_api'}).href, options).then(response => {
                    this.loading = false;
                    resolve(response);
                }).catch(error => {
                    this.loading = false;
                    reject(error);
                });
            });
        },
        updateUserGroup(router: Router, userRequest: UserGroupRequest, id: number)  {
            this.loading = true;

            const options = { body: JSON.stringify(userRequest), method: 'PATCH' };

            return new Promise((resolve, reject) => {
                fetch(router.resolve({name: 'admin_user_groups_api', params: { id: id }}).href, options).then(response => {
                    this.loading = false;
                    resolve(response);
                }).catch(error => {
                    this.loading = false;
                    reject(error);
                });
            });
        },

        deleteUserGroup(router: Router, id: number) {
            this.loading = true;

            return new Promise<void>((resolve, reject) => {
                fetch(router.resolve({ name: 'admin_user_groups_api', params: { id: id } }).href, { method: 'DELETE' })
                    .then(response => {
                        this.loading = false;
                        resolve();
                    }).catch(error => {
                    this.loading = false;
                    reject(error);
                });
            });
        },
    },
})
