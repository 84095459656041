import {defineStore} from "pinia";
import fetch from "../../common/utils/fetch";
import type ShopResponseType from "../../common/utils/ShopResponseType";
import {type Router} from "vue-router";
import type UserStateInterface from "./UserState";
import {type UserInterface} from "../../models/User";
import type UserQuery from "./UserQuery";
import UserRequest from "./UserRequest";

export const useUserStore = defineStore('userStore', {
    state: (): UserStateInterface => ({
        loading: false,
        users: [],
        totalUsers: 0
    }),
    getters: {
    },
    actions: {
        getUsers(router: Router, query: UserQuery): Promise<ShopResponseType> {
            this.loading = true;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'admin_users_api', query: query }).href)
                    .then((response: ShopResponseType) => {
                        const users: UserInterface[] = [];

                        if (Array.isArray(response['hydra:member'])) {
                            response['hydra:member'].forEach(element => {
                                if (element['@type'] !== 'User') {
                                    return;
                                }
                                users.push(element);
                            });
                        }

                        this.users = users;
                        this.loading = false;
                        this.totalUsers = response['hydra:totalItems'];
                        resolve(response);
                    })
                    .catch(error => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },
        reset() {
            this.users = [];
            this.totalUsers = 0;
        },
        createUser(router: Router, userRequest: UserRequest) {
            this.loading = true;

            const options = { body: JSON.stringify(userRequest), method: 'POST' };

            return new Promise((resolve, reject) => {
                fetch(router.resolve({name: 'admin_users_api'}).href, options).then(response => {
                    this.loading = false;
                    resolve(response);
                }).catch(error => {
                    this.loading = false;
                    reject(error);
                });
            });
        },
        updateUser(router: Router, userRequest: UserRequest, id: number)  {
            this.loading = true;

            const options = { body: JSON.stringify(userRequest), method: 'PATCH' };

            return new Promise((resolve, reject) => {
                fetch(router.resolve({name: 'admin_users_api', params: { id: id }}).href, options).then(response => {
                    this.loading = false;
                    resolve(response);
                }).catch(error => {
                    this.loading = false;
                    reject(error);
                });
            });
        },

        deleteUser(router: Router, id: number) {
            this.loading = true;

            return new Promise<void>((resolve, reject) => {
                fetch(router.resolve({ name: 'admin_users_api', params: { id: id } }).href, { method: 'DELETE' })
                    .then(response => {
                        this.loading = false;
                        resolve();
                    }).catch(error => {
                    this.loading = false;
                    reject(error);
                });
            });
        },
    },
})
