import Login from './../../pages/login/Login.vue';

export default [
    {
        name: 'app_login',
        path: '/login/',
        component: Login,
        meta: { requiresAuth: false, title: 'Login' }
    },
    {
        name: 'security_login',
        path: '/api/security/login',
        redirect: '',
        meta: { requiresAuth: false }
    },
    {
        name: 'security_logout',
        path: '/api/security/logout',
        redirect: '',
        meta: { requiresAuth: false }
    },
    {
        name: 'security_refresh_token',
        path: '/api/security/refresh-token',
        redirect: '',
        meta: { requiresAuth: false }
    },
    {
        name: 'security_get_user',
        path: '/api/security/get-user',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'permissions_api',
        path: '/api/permissions/:id(\\d+)?',
        redirect: '',
        meta: { requiresAuth: true }
    }
];
