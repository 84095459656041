import JwtDecode from "jwt-decode";

export default class AuthenticatedUser {
    id: number;
    iri: string;
    email: string;
    permissions: string[];
    exp: number;
    externalId?: string;
    site: string;
    groups: number[];

    private static token: string;
    private static refreshToken: string;

    public static factory(jwtToken: string = '', refreshToken: string = ''): AuthenticatedUser | null {
        try {
            if (jwtToken.length === 0) {
                jwtToken = sessionStorage.getItem('token') ?? '';
            } else {
                sessionStorage.setItem('token', jwtToken);
            }
            if (refreshToken.length === 0) {
                refreshToken = sessionStorage.getItem('refreshToken') ?? '';
            } else {
                sessionStorage.setItem('refreshToken', refreshToken);
            }
            AuthenticatedUser.token = jwtToken;
            AuthenticatedUser.refreshToken = refreshToken;
            return new AuthenticatedUser(JwtDecode(jwtToken));
        } catch (_) {
            return null;
        }
    }

    private constructor({ id, iri, email, permissions, exp, externalId, site, groups }: AuthenticatedUser) {
        this.id = id;
        this.iri = iri;
        this.email = email;
        this.permissions = permissions;
        this.exp = exp;
        this.externalId = externalId;
        this.site = site;
        this.groups = groups;
    }

    public static getToken() {
        return AuthenticatedUser.token;
    }

    public static getRefreshToken() {
        return AuthenticatedUser.refreshToken;
    }

    public hasPermission(permission: string): boolean {
        if (!Array.isArray(this.permissions)) {
            return false;
        }

        return this.permissions.includes(permission);
    }
}
