<template>
    <button type="button"
            class="btn btn-rounded btn-link"
            @click="click()">
        <span v-if="column !== undefined && ['key','context'].includes(column)"
              class="pr-2 text-white">
            {{label}}
        </span>
        <span v-else class="pr-2 text-white">
            {{label}}
        </span>

        <span v-if="filters !== undefined && 'key' === filters.orderBy" class="text-white">
                                <i v-if="'asc' === filters.orderDirection" class="marso-icon-sort-asc"></i>
                                <i v-if="'desc' === filters.orderDirection" class="marso-icon-sort-desc"></i>
                        </span>
        <span v-else class="text-white"><i class="marso-icon-sort"></i></span>
    </button>
</template>

<script setup lang="ts">

import Filters from "../../stores/translation/Filters";

const emit = defineEmits(['click']);

defineProps({
    filters: {
        type: Filters
    },
    column: {
        type: String
    },
    label: {
        type: String
    }
})

const click = () => {
    emit('click');
}
</script>
