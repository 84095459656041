'use strict';

import fetchWrapper from './fetchWrapper';
import type ShopResponseType from './ShopResponseType';
import { type ComponentInternalInstance, getCurrentInstance } from 'vue';
import AuthenticatedUser from "../../stores/security/AuthenticatedUser";
import ValidationServerError from "../error/ValidationServerError";
import InternalServerError from "../error/InternalServerError";
import BadRequestServerError from "../error/BadRequestServerError";
import ForbiddenServerError from "../error/ForbiddenServerError";
import UnauthorizedServerError from "../error/UnauthorizedServerError";

class ProgressState {
    protected static app: ComponentInternalInstance;
    protected static poll: number = 0;
    protected static hasFailed: boolean = false;

    public static start(fetchPromise: Promise<Response>) {
        const appLoadingElement = document.getElementById('appLoading');

        if (appLoadingElement !== null) {
            appLoadingElement.style.display = 'flex';
        }

        if (!ProgressState.app) {
            const app = getCurrentInstance();

            if (app) {
                ProgressState.app = app;
            }
        }

        ProgressState.poll++;

        fetchPromise
            .then(() => {
                ProgressState.poll--;
            })
            .catch(() => {
                ProgressState.poll--;
                ProgressState.hasFailed = true;
            })
            .finally(() => {
                if (ProgressState.poll <= 0) {
                    if (appLoadingElement !== null) {
                        appLoadingElement.style.display = 'none';
                    }
                    ProgressState.finish();
                }
            });
    }

    protected static finish() {
        ProgressState.poll = 0;
        ProgressState.hasFailed = false;
    }
}

export declare function shopFetch(request: Request | string, options?: RequestInit): Promise<ShopResponseType>;

const fetch: typeof shopFetch = function (
    request: Request | string,
    options: RequestInit = {}
): Promise<ShopResponseType> {

    if ('undefined' === typeof options.headers) {
        options.headers = new Headers();
    }

    if (!(options.headers instanceof Headers) && typeof options.headers === 'object') {
        options.headers = new Headers(options.headers);
    }

    if (AuthenticatedUser.getToken()) {
        options.headers.set('Authorization', `Bearer ${AuthenticatedUser.getToken()}`);
    }

    if (options.method === 'PATCH') {
        options.headers.set('Content-Type', 'application/merge-patch+json');
    }

    const promise = fetchWrapper(request, options).then(async (response: Response) => {
        if (response.ok && response.status === 204) {
            return response;
        }

        if (response.ok) {
            return response.json();
        }

        const data = await response.json();

        if (response.status === 401) {
            throw new UnauthorizedServerError(response, data);
        }

        if (response.status === 403) {
            let redirect = '';

            if (data.redirect && data.redirect.length) {
                redirect = data.redirect;
            } else {
                redirect = window.location.host;
            }

            throw new ForbiddenServerError(response, data, redirect);
        }

        if (response.status === 400) {
            throw new BadRequestServerError(response, data);
        }

        if (response.status === 422) {
            throw new ValidationServerError(response, data);
        }


        throw new InternalServerError(response, data);
    });

    ProgressState.start(promise);

    return promise;
};

export default fetch;
