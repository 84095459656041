<template>
    <div class="w-full 2xl:container mx-auto p-4 flex flex-col">
        <div class="flex justify-end">
            <button
                class="btn btn-rounded btn-primary"
                @click="openCreateUserModal()"
            >
                <i class="marso-icon-plus"></i>
                {{ Translator.trans('add_user_button', 'Add User', 'users') }}
            </button>
        </div>

        <responsive-table
            v-model:fields="listFields"
            :items="userStore.users"
            striped
            condensed
            panel
            class="mt-4"
            :isBusy="userStore.loading"
            v-model:sortBy="sortBy"
            v-model:sortDesc="sortDesc"
            pagination
            v-model:currentPage="page"
            v-model:itemsPerPage="itemsPerPage"
            :totalItems="userStore.totalUsers"
        >
            <template #cell-actions="{ item: user }">
                <div class="flex flex-row gap-1 justify-center items-center">
                    <button
                        class="btn btn-rounded btn-primary btn-link"
                        @click="openUpdateUserModal(user as UserInterface)"
                    >
                        <i class="marso-icon-pencil"></i>
                        {{ Translator.trans('edit', 'Edit', 'users') }}
                    </button>
                    <button
                        class="btn btn-rounded btn-danger btn-link"
                        @click="deleteUser(user as UserInterface)"
                    >
                        <i class="marso-icon-trash"></i>
                        {{ Translator.trans('delete', 'Delete', 'users') }}
                    </button>
                </div>
            </template>
        </responsive-table>

        <modal
            :isOpen="modalIsOpen"
            :title="selectedUser !== null ? Translator.trans(
                                                    'edit_user_modal_title',
                                                    'Edit User: {user}',
                                                    'users',
                                                    { user: selectedUser.email })
                                                    : Translator.trans('add_user', 'Add User', 'users')"
            @close="modalIsOpen = false"
        >
            <alert
                :title="Translator.trans('save_error', 'Save error', 'users')"
                :message="errorMessage"
                v-if="errorMessage.length"
            />

            <shop-input
                :placeholder="Translator.trans('user_email_placeholder', 'User Email', 'users')"
                :label="Translator.trans('user_email_label', 'User Email', 'users')"
                type="email"
                autofocus
                required
                v-model="userRequest.email"
                class="my-5"
                border
            />

            <shop-input
                :placeholder="Translator.trans('password_placeholder', 'Password', 'users')"
                :label="Translator.trans('password_label', 'Password', 'users')"
                type="password"
                required
                :autocomplete="false"
                v-model="userRequest.plainPassword"
                class="my-5"
                border
            />

            <shop-input
                :placeholder="Translator.trans('password_repeat_placeholder', 'Password repeat', 'users')"
                :label="Translator.trans('password_repeat_label', 'Password repeat', 'users')"
                type="password"
                required
                :autocomplete="false"
                v-model="plainPasswordConfirmation"
                class="my-5"
                border
            />

            <shop-input
                :placeholder="Translator.trans('external_id_placeholder', 'External ID', 'users')"
                :label="Translator.trans('external_id_label', 'External ID', 'users')"
                type="text"
                required
                v-model="userRequest.externalId"
                class="my-5"
                border
            />

            <select-input v-model="userRequest.site"
                          :placeholder-enable="true"
                          class="mb-5"
                          :placeholder="Translator.trans('site_placeholder', '-- Please choose a site --', 'users')"
                          :label="Translator.trans('site_label', 'Site', 'users')"
                          :options="siteSelectOptions"/>

            <responsive-table
                v-model:fields="userGroupsListFields"
                :items="userGroupStore.userGroups"
                striped
                condensed
                class="mt-4"
                :isBusy="userGroupStore.loading"
            >
                <template #cell-actions="{ item: userGroup }">
                    <toggle
                        :checked="userGroupChecked(userGroup as UserGroupInterface)"
                        @change="checked => { userGroupCheckboxChange(checked, userGroup as UserGroupInterface) }"
                    />
                </template>
            </responsive-table>

            <div class="mt-4 pt-4 border-t-1 border-gray flex justify-end">
                <button
                    class="btn btn-rounded btn-primary"
                    @click="selectedUser === null ? createUser() : updateUser()"
                >
                    <i class="marso-icon-check"></i>
                    {{ Translator.trans('save', 'Save', 'users') }}
                </button>
            </div>
        </modal>
    </div>
</template>

<script setup lang="ts">
import {
    computed,
    type ComputedRef,
    onBeforeMount,
    onBeforeUnmount,
    ref,
    type Ref,
    watch,
    watchEffect
} from "vue";
import ResponsiveTable from "../../components/ResponsiveTable/ResponsiveTable.vue";
import type FieldDefinitionInterface from "../../components/ResponsiveTable";
import { type UserInterface } from "../../models/User";
import { Translator } from "../../common/i18n";
import Modal from "../../components/Modal.vue";
import Alert from "../../components/Alert.vue";
import { ShopInput, Toggle } from "../../components/Form";
import SelectInput from "../../components/Form/Select/SelectInput.vue";
import { type UserGroupInterface } from "../../models/UserGroup";
import ServerError from "../../common/error/ServerError";
import UserRequest from "../../stores/user/UserRequest";
import {useToastStore} from "../../stores/toast/toastStore";
import type UserQuery from "../../stores/user/UserQuery";
import {useUserStore} from "../../stores/user/userStore";
import {useSiteStore} from "../../stores/site/siteStore";
import {useUserGroupStore} from "../../stores/userGroup/userGroupStore";
import {useRouter} from "vue-router";
import Option from "@/components/Form/Select/Option";

const router = useRouter();
const userStore = useUserStore();
const userGroupStore = useUserGroupStore();
const toastStore = useToastStore();
const siteStore = useSiteStore();

const modalIsOpen: Ref<boolean> = ref(false);
const errorMessage: Ref<string> = ref("");
const userRequest: Ref<UserRequest> = ref(new UserRequest());
const selectedUser: Ref<UserInterface | null> = ref(null);
const sortBy: Ref<string> = ref("id");
const sortDesc: Ref<boolean> = ref(false);
const page: Ref<number> = ref(1);
const itemsPerPage: Ref<number> = ref(20);
const plainPasswordConfirmation: Ref<string> = ref('');

siteStore.getSites(router);

const siteSelectOptions: ComputedRef<Array<Option>> = computed(() => {
    return siteStore.sites.map(site => new Option(
        site['@id'],
        site.siteName,
        userRequest.value.site === site['@id']
    ));
});

const listFields: Ref<FieldDefinitionInterface[]> = ref<
    FieldDefinitionInterface[]
>([
    {
        key: "id",
        label: Translator.trans('id', "ID", 'users') ?? "ID",
        value: "id",
        sortable: true,
        filterable: true,
    },
    {
        key: "email",
        label: Translator.trans('email', "Email", 'users') ?? "Email",
        value: "email",
        filterable: true,
        sortable: true,
    },
    {
        key: "externalId",
        label: Translator.trans('external_id', "External ID", 'users') ?? "External ID",
        value: "externalId",
        filterable: true,
        sortable: true,
    },
    {
        key: "actions",
        label: "",
    },
]);
const userGroupsListFields: Ref<FieldDefinitionInterface[]> = ref<
    FieldDefinitionInterface[]
>([
    {
        key: "name",
        label: Translator.trans('user_group', "User Group", 'users') ?? "User Group",
        value: "name",
    },
    {
        key: "actions",
        label: "",
    },
]);


const userQuery: ComputedRef<UserQuery> = computed(() => {
    const filters: {[key: string]: string | number } = {};

    listFields.value.forEach((field: FieldDefinitionInterface) => {
        if (field.filterable && field.filterValue) {
            filters[field.key] = field.filterValue;
        }
    });

    let order = `order[${sortBy.value}]`;

    return {
        page: page.value,
        itemsPerPage: itemsPerPage.value,
        [order]: sortDesc.value ? 'desc' : 'asc',
        ...filters
    };
});

onBeforeUnmount(() => {
    userStore.reset();
});

onBeforeMount(() => {
    userGroupStore.getUserGroups(router)
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
})

watchEffect(
    () => {
        userStore.getUsers(router, userQuery.value)
            .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
    }
);

const openCreateUserModal = () => {
    userRequest.value = new UserRequest();
    selectedUser.value = null;
    modalIsOpen.value = true;
};

const openUpdateUserModal = (user: UserInterface) => {
    selectedUser.value = user;

    userRequest.value = new UserRequest(
        null,
        user.email,
        user.groups.map((userGroup: UserGroupInterface) => userGroup['@id']),
        user.externalId,
        user.site
    );

    modalIsOpen.value = true;
};

const deleteUser = (user: UserInterface) => {
    if (!confirm(Translator.trans('delete_user_confirm', "Are you sure?", 'users'))) {
        return;
    }
    errorMessage.value = "";
    userStore.deleteUser(router, user.id)
        .then(() => {
            userStore.reset();
            userStore.getUsers(router, userQuery.value)
                .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
        })
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
};

const createUser = () => {
    errorMessage.value = "";

    if (userRequest.value.plainPassword !== plainPasswordConfirmation.value) {
        errorMessage.value = Translator.trans('passwords_not_match',
            'Given password and the confirmation does not match.',
            'users') ?? 'Given password and the confirmation does not match';
        return;
    }

    userStore.createUser(router, userRequest.value)
        .then(() => {
            modalIsOpen.value = false;
            userStore.reset();
            page.value = 1;
            userStore.getUsers(router, userQuery.value)
                .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
        })
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
}

const updateUser = () => {
    errorMessage.value = "";

    if (
        userRequest.value.plainPassword !== null
        && userRequest.value.plainPassword !== ''
        && userRequest.value.plainPassword !== plainPasswordConfirmation.value) {
        errorMessage.value = Translator.trans('passwords_not_match',
            'Given password and the confirmation does not match.',
            'users') ?? 'Given password and the confirmation does not match';
        return;
    }

    if (selectedUser.value === null) {
        errorMessage.value = Translator.trans('user_not_selected',
            'User not selected',
            'users') ?? 'User not selected';
        return;
    }

    if (userRequest.value.plainPassword === '') {
        userRequest.value.plainPassword = null;
    }

    userStore.updateUser(router, userRequest.value, selectedUser.value.id)
        .then(() => {
            modalIsOpen.value = false;
            userStore.reset();
            page.value = 1;
            userStore.getUsers(router, userQuery.value)
                .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
        })
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
}

watch(modalIsOpen, (value) => {
    if (!value) {
        errorMessage.value = "";
    }
});

const userGroupCheckboxChange = (
    checked: Boolean,
    userGroup: UserGroupInterface
) => {
    if (!checked) {
        userRequest.value.groups = userRequest.value.groups
            .filter((userGroupIri: string) => userGroupIri !== userGroup["@id"]);

        return;
    }

    let userGroupIri: string | undefined = userGroup["@id"];

    if (userGroupIri === undefined) {
        return;
    }

    userRequest.value.groups.push(userGroupIri)
};

const userGroupChecked = (userGroup: UserGroupInterface): boolean => {
    let userGroupIri: string | undefined = userGroup["@id"];

    if (userGroupIri === undefined) {
        return false;
    }

    return userRequest.value.groups.includes(userGroupIri);
}
</script>
