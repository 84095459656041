import { defineStore, type Store } from 'pinia';
import type DocumentStateInterface from './DocumentState';
import { EventSourcePolyfill } from 'event-source-polyfill';
import appFetch from '../../common/utils/fetch';
import { useConfigurationStore } from '../configuration/configurationStore';
import type { MessageEvent } from 'event-source-polyfill';
import type { Event } from 'event-source-polyfill';
import type { DocumentInterface } from '@/models/Document';
import { useSecurityStore } from '../security/securityStore';
import { useToastStore } from '../toast/toastStore';
import { Translator } from '@/common/i18n';
import { DocumentStatusEnum } from '@/models/Document';
import { ToastTypeEnum } from '../toast/ToastInterface';
import { useDocumentSignerStore, type DocumentSignerStoreType } from './documentSignerStore';

export type DocumentStoreType = Store<
    'documentStore', // id
    DocumentStateInterface, // state
    {}, // getters
    {
        //actions
        registerListener(): void;
        sign(document: DocumentInterface): Promise<void>;
        reject(document: DocumentInterface): Promise<void>;
        accept(document: DocumentInterface): Promise<void>;
        enqueueDocument(document: DocumentInterface): void;
        removeDocument(document: DocumentInterface): void;
        updateDocument(document: DocumentInterface): void;
    }
>;

export const useDocumentStore = defineStore('documentStore', {
    state: (): DocumentStateInterface => ({
        registered: false,
        eventSource: null,
        documents: new Array<DocumentInterface>(),
        lastEventId: null,
        toastStore: useToastStore(),
        documentSignerStore: useDocumentSignerStore() as unknown as DocumentSignerStoreType,
        interval: null,
    }),
    getters: {},
    actions: {
        enqueueDocument(document: DocumentInterface) {
            if (document.status !== DocumentStatusEnum.pending) {
                return;
            }

            this.documents.push(document);
        },
        registerListener() {
            if (this.registered) {
                return;
            }
            // if reported as not registered, then close the previous event source if exists
            if (this.eventSource !== null) {
                this.eventSource.close();
            }

            const securityStore = useSecurityStore();
            const configurationStore = useConfigurationStore();
            if (!configurationStore.loaded || securityStore.authenticatedUser === null) {
                return;
            }
            const hubUrl = new URL(`https://mercure.${window.location.host}/.well-known/mercure`);
            const defaultTopic = `${window.location.origin}/api/documents/{documentId}?site=${securityStore.authenticatedUser.site}`;
            securityStore.authenticatedUser.groups.forEach((groupId) => {
                hubUrl.searchParams.append(
                    'topic',
                    `${defaultTopic}&userId=${securityStore.authenticatedUser?.externalId}&groupId=${groupId}`
                );
            });
            hubUrl.searchParams.append('topic', `${defaultTopic}&userId=${securityStore.authenticatedUser.externalId}`);
            securityStore.authenticatedUser.groups.forEach((groupId) => {
                hubUrl.searchParams.append('topic', `${defaultTopic}&groupId=${groupId}`);
            });
            hubUrl.searchParams.append('topic', defaultTopic);
            try {
                this.eventSource = new EventSourcePolyfill(hubUrl.toString(), {
                    headers: {
                        Authorization: `Bearer ${configurationStore.configuration.mercureSubscriberKey}`,
                    },
                    withCredentials: true,
                });
            } catch (error) {
                this.registered = false;
                this.eventSource = null;
                this.registerListener();
                return;
            }

            const isMessageEvent = (event: Event): event is MessageEvent => {
                return event instanceof MessageEvent || typeof (event as MessageEvent).data !== 'undefined';
            };

            this.eventSource.addEventListener('create', (event: Event) => {
                if (!isMessageEvent(event)) {
                    return;
                }
                this.enqueueDocument(JSON.parse(event.data) as DocumentInterface);

                this.lastEventId = event.lastEventId;
            });

            this.eventSource.addEventListener('update', (event: Event) => {
                if (!isMessageEvent(event)) {
                    return;
                }

                const document = JSON.parse(event.data) as DocumentInterface;

                if (document.status === DocumentStatusEnum.rejected) {
                    this.removeDocument(document);
                    return;
                }

                this.updateDocument(document);

                if (
                    this.documentSignerStore.currentDocument !== null
                    && this.documentSignerStore.currentDocument.id === document.id
                ) {
                    this.documentSignerStore.currentDocument = null;
                    this.documentSignerStore.currentDocument = document;
                    this.toastStore.addToast({
                        type: ToastTypeEnum.Warning,
                        message: Translator.trans('document_updated', 'Document updated', 'signer'),
                    });
                }

                this.lastEventId = event.lastEventId;
            });

            this.eventSource.addEventListener('delete', (event: Event) => {
                if (!isMessageEvent(event)) {
                    return;
                }

                const document = JSON.parse(event.data) as DocumentInterface;

                this.removeDocument(document);

                if (
                    this.documentSignerStore.currentDocument !== null
                    && this.documentSignerStore.currentDocument.id === document.id
                ) {
                    this.documentSignerStore.currentDocument = null;
                    this.toastStore.addToast({
                        type: ToastTypeEnum.Warning,
                        message: Translator.trans('document_removed', 'Document removed', 'signer'),
                    });
                }

                this.lastEventId = event.lastEventId;
            });

            this.eventSource.onerror = (e: Event) => {
                if (this.eventSource === null) {
                    return;
                }

                // 0 — connecting
                // 1 — open
                // 2 — closed
                if (this.eventSource.readyState === EventSourcePolyfill.OPEN) {
                    this.eventSource.close();
                }

                this.registered = false;
                this.registerListener();
            };
            this.eventSource.onopen = (e: Event) => {
                this.registered = true;
            };

            if (this.interval !== null) {
                window.clearInterval(this.interval);
            }

            this.interval = window.setInterval(() => {
                if (this.eventSource?.readyState === EventSourcePolyfill.CLOSED) {
                    this.registered = false;
                    this.registerListener();
                }
            }, 1000);
        },
        sign(document: DocumentInterface) {
            return new Promise<void>((resolve, reject) => {
                appFetch(`${window.location.origin}/api/documents/${document.id}/sign`, {
                    method: 'PATCH',
                    body: JSON.stringify(document),
                }).then(() => {
                    resolve();
                    this.toastStore.addToast({
                        type: ToastTypeEnum.Success,
                        message: Translator.trans('document_saved', 'Document saved', 'signer'),
                    });
                });
            });
        },
        reject(document: DocumentInterface) {
            return new Promise<void>((resolve, reject) => {
                appFetch(`${window.location.origin}/api/documents/${document.id}/reject`, {
                    method: 'PATCH',
                    body: JSON.stringify({}),
                }).then(() => {
                    resolve();
                    this.toastStore.addToast({
                        type: ToastTypeEnum.Warning,
                        message: Translator.trans('document_rejected', 'Document rejected', 'signer'),
                    });
                });
            });
        },
        accept(document: DocumentInterface) {
            return new Promise<void>((resolve, reject) => {
                appFetch(`${window.location.origin}/api/documents/${document.id}/accept`, {
                    method: 'PATCH',
                    body: JSON.stringify({}),
                }).then(() => {
                    resolve();
                    this.toastStore.addToast({
                        type: ToastTypeEnum.Success,
                        message: Translator.trans('document_accepted', 'Document accepted', 'signer'),
                    });
                });
            })
        },
        removeDocument(document: DocumentInterface) {
            this.documents = this.documents.filter((doc) => doc.id !== document.id);
        },
        updateDocument(document: DocumentInterface) {
            this.documents = this.documents.map((doc) => (doc.id === document.id ? document : doc));
        }
    },
});
