export interface CoordinateInterface {
    x: number;
    y: number;
}

export class Coordinate implements CoordinateInterface {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}

export interface AnnotationVectorInterface {
    from: CoordinateInterface;
    to: CoordinateInterface;
}

export class AnnotationVector implements AnnotationVectorInterface {
    from: CoordinateInterface;
    to: CoordinateInterface;

    constructor(from: CoordinateInterface, to: CoordinateInterface) {
        this.from = from;
        this.to = to;
    }
}

export interface AnnotationPathInterface {
    page: number;
    path: AnnotationVectorInterface[];

    push(vector: AnnotationVectorInterface): void;
    replaceAll(items: AnnotationVectorInterface[]): void;
}

export class AnnotationPath implements AnnotationPathInterface {
    page: number;
    path: AnnotationVectorInterface[];

    constructor(page: number) {
        this.page = page;
        this.path = [];
    }

    push(vector: AnnotationVectorInterface): void {
        this.path.push(vector);
    }

    replaceAll(items: AnnotationVectorInterface[]): void {
        this.path = items;
    }
}
