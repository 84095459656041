<template>
    <div class="w-full 2xl:container mx-auto p-4 flex flex-col">
        <div class="flex justify-end">
            <button class="btn btn-rounded btn-primary" @click="openCreateUserGroupModal()">
                <i class="marso-icon-plus"></i>
                {{ Translator.trans('add_user_group_button', 'Add User Group', 'user_groups') }}
            </button>
        </div>

        <responsive-table
            v-model:fields="listFields"
            :items="userGroupStore.userGroups"
            striped
            condensed
            panel
            class="mt-4"
            :isBusy="userGroupStore.loading"
            v-model:sortBy="sortBy"
            v-model:sortDesc="sortDesc"
        >
            <template #cell-actions="{ item: userGroup }">
                <div class="flex flex-row gap-1 justify-center items-center">
                    <button class="btn btn-rounded btn-primary btn-link" @click="openUpdateUserModal(userGroup as UserGroupInterface)">
                        <i class="marso-icon-pencil"></i>
                        {{ Translator.trans('edit', 'Edit', 'user_groups') }}
                    </button>
                    <button class="btn btn-rounded btn-danger btn-link" @click="deleteUserGroup(userGroup as UserGroupInterface)">
                        <i class="marso-icon-trash"></i>
                        {{ Translator.trans('delete', 'Delete', 'user_groups') }}
                    </button>
                </div>
            </template>
        </responsive-table>

        <modal
            :isOpen="modalIsOpen"
            :title="selectedUserGroup !== null ? Translator.trans(
                                                        'edit_user_group_modal_title',
                                                        'Edit User Group: {group}',
                                                        'user_groups',
                                                        { group: selectedUserGroup.name })
                                                        : Translator.trans('add_user_group', 'Add User Group', 'user_groups')"
            @close="modalIsOpen = false"
        >
            <alert
                :title="Translator.trans('save_error', 'Save error', 'user_groups')"
                :message="errorMessage"
                v-if="errorMessage.length"
            />

            <shop-input
                :placeholder="Translator.trans('user_group_name_placeholder', 'User Group Name', 'user_groups')"
                :label="Translator.trans('user_group_name_label', 'User Group Name', 'user_groups')"
                autofocus
                required
                v-model="userGroupRequest.name"
                class="my-5"
                border
            />

            <shop-input
                :placeholder="Translator.trans('user_group_key_placeholder', 'User Group Key', 'user_groups')"
                :label="Translator.trans('user_group_key_label', 'User Group Key', 'user_groups')"
                required
                v-model="userGroupRequest.key"
                class="my-5"
                border
            />

            <responsive-table
                v-for="(permissions, group) in permissionStore.permissionGroupped"
                :key="group"
                :fields="[{ key: 'group', label: group, value: '' }]"
                :items="permissions"
                striped
                condensed
                class="mt-4"
                :isBusy="permissionStore.loading"
            >
                <template #cell-group="{ item: permission }">
                    <toggle
                        :label="(permission as PermissionInterface).description"
                        :checked="userGroupRequest.permissions.findIndex((permissionIri: string) => permissionIri === (permission as PermissionInterface)['@id']) !== -1"
                        @change="checked => { permissionCheckboxChange(checked, permission as PermissionInterface) }"
                        reversed
                    />
                </template>
            </responsive-table>

            <div class="mt-4 pt-4 border-t-1 border-gray flex justify-end">
                <button class="btn btn-rounded btn-primary"
                        @click="selectedUserGroup === null ? createUserGroup() : updateUserGroup()"
                >
                    <i class="marso-icon-check"></i>
                    {{ Translator.trans('save', 'Save', 'user_groups') }}
                </button>
            </div>
        </modal>
    </div>
</template>

<script setup lang="ts">
import {computed, type ComputedRef, onBeforeMount, onBeforeUnmount, type Ref, ref, watch, watchEffect} from "vue";
import { Translator } from "../../common/i18n";
import { type UserGroupInterface } from "../../models/UserGroup";
import ResponsiveTable from "../../components/ResponsiveTable/ResponsiveTable.vue";
import type FieldDefinitionInterface from "../../components/ResponsiveTable";
import Modal from "../../components/Modal.vue";
import Alert from "../../components/Alert.vue";
import { ShopInput, Toggle } from "../../components/Form";
import { type PermissionInterface } from "../../models/Permission";
import ServerError from "../../common/error/ServerError";
import {usePermissionStore} from "../../stores/permission/permissionStore";
import {useRouter} from "vue-router";
import {useToastStore} from "../../stores/toast/toastStore";
import {useUserGroupStore} from "../../stores/userGroup/userGroupStore";
import type UserGroupQuery from "../../stores/userGroup/UserGroupQuery";
import UserGroupRequest from "../../stores/userGroup/UserGroupRequest";

const errorMessage = ref('');
const router = useRouter();
const permissionStore = usePermissionStore();
const toastStore = useToastStore();
const userGroupStore = useUserGroupStore();

const userGroupRequest: Ref<UserGroupRequest> = ref(new UserGroupRequest());
const selectedUserGroup = ref<UserGroupInterface | null>(null);

const page: Ref<number> = ref(1);
const itemsPerPage: Ref<number> = ref(20);
const sortBy: Ref<string> = ref('id');
const sortDesc: Ref<boolean> = ref(false);
const modalIsOpen = ref(false);

const listFields: Ref<FieldDefinitionInterface[]> = ref<FieldDefinitionInterface[]>([
    {
        key: "id",
        label: Translator.trans('id', "ID", 'user_groups') ?? 'ID',
        value: "id",
        sortable: true,
        filterable: true,
    },
    {
        key: "key",
        label: Translator.trans('key', "Key", 'user_groups') ?? 'Key',
        value: "key",
        filterable: true,
        sortable: true,
    },
    {
        key: "name",
        label: Translator.trans('name', "Name", 'user_groups') ?? 'Name',
        value: "name",
        filterable: true,
        sortable: true,
    },
    {
        key: "actions",
        label: "",
    },
]);

onBeforeMount(() => {
    permissionStore.getPermissions(router, {
        'order[group]': 'asc',
        'order[key]': 'asc'
    });
})

onBeforeUnmount(() => {
    userGroupStore.userGroups = [];
});

const openCreateUserGroupModal = () => {
    userGroupRequest.value = new UserGroupRequest();
    selectedUserGroup.value = null;
    modalIsOpen.value = true;
};

const openUpdateUserModal = (userGroup: UserGroupInterface) => {
    selectedUserGroup.value = userGroup;
    userGroupRequest.value = new UserGroupRequest(
        userGroup.name,
        userGroup.key,
        userGroup.permissions.map((permission: PermissionInterface) => permission['@id'])
    );
    modalIsOpen.value = true;
};

const deleteUserGroup = (userGroup: UserGroupInterface) => {
    if (!confirm(Translator.trans('delete_user_group_confirm', 'Are you sure?', 'user_groups'))) {
        return;
    }
    errorMessage.value = "";
    userGroupStore.deleteUserGroup(router, userGroup.id)
        .then(() => {
            userGroupStore.userGroups = [];
            userGroupStore.getUserGroups(router)
                .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
        })
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
};

const permissionCheckboxChange = (checked: boolean, permission: PermissionInterface) => {
    if (checked && userGroupRequest.value.permissions.findIndex((permissionIri: string) => permissionIri === permission['@id']) === -1) {
        userGroupRequest.value.permissions.push(permission['@id']);

        return;
    }

    if (!checked && userGroupRequest.value.permissions.findIndex((permissionIri: string) => permissionIri === permission['@id']) !== -1) {
        userGroupRequest.value.permissions = userGroupRequest.value.permissions
            .filter((permissionIri: string) => permission['@id'] !== permissionIri);
    }
};

const createUserGroup = () => {
    errorMessage.value = "";

    userGroupStore.createUserGroup(router, userGroupRequest.value)
        .then(() => {
            modalIsOpen.value = false;
            userGroupStore.userGroups = [];
            page.value = 1;
            userGroupStore.getUserGroups(router, userGroupQuery.value)
                .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
        })
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
}

const updateUserGroup = () => {
    errorMessage.value = "";

    if (selectedUserGroup.value === null) {
        errorMessage.value = Translator.trans('userGroup_not_selected',
            'UserGroup not selected',
            'userGroups') ?? 'UserGroup not selected';
        return;
    }

    userGroupStore.updateUserGroup(router, userGroupRequest.value, selectedUserGroup.value.id)
        .then(() => {
            modalIsOpen.value = false;
            userGroupStore.userGroups = [];
            page.value = 1;
            userGroupStore.getUserGroups(router, userGroupQuery.value)
                .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
        })
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
}

watch(modalIsOpen, (value) => {
    if (!value) {
        errorMessage.value = '';
    }
});

const userGroupQuery: ComputedRef<UserGroupQuery> = computed(() => {
    const filters: {[key: string]: string | number } = {};

    listFields.value.forEach((field: FieldDefinitionInterface) => {
        if (field.filterable && field.filterValue) {
            filters[field.key] = field.filterValue;
        }
    });

    let order = `order[${sortBy.value}]`;

    return {
        page: page.value,
        itemsPerPage: itemsPerPage.value,
        [order]: sortDesc.value ? 'desc' : 'asc',
        ...filters
    };
});

watchEffect(
    () => {
        userGroupStore.getUserGroups(router, userGroupQuery.value)
            .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
    }
);
</script>
