import { defineStore } from 'pinia';
import type ConfigurationStateInterface from './ConfigurationStateInterface';
import { type Router } from 'vue-router';
import type ShopResponseType from '../../common/utils/ShopResponseType';
import fetch from '../../common/utils/fetch';
import { type ConfigurationInterface } from '@/models/Configuration';

export const useConfigurationStore = defineStore('configurationStore', {
    state: (): ConfigurationStateInterface => ({
        loaded: false,
        configuration: {
            env: 'prod',
            locale: 'en_EN',
            location: 'HUN',
            mercureSubscriberKey: ''
        },
    }),
    actions: {
        load(router: Router): Promise<ShopResponseType> {
            if (this.loaded) {
                return Promise.resolve(this.configuration);
            }
            return new Promise((resolve, reject) => {
                this.loaded = false;
                fetch(router.resolve({ name: 'admin_configuration_api' }).href)
                    .then((response) => {
                        if (response['@type'] !== 'ConfigurationDto') {
                            reject(new Error('Could not load configuration'));
                            return;
                        }
                        this.configuration = response as ConfigurationInterface;
                        this.loaded = true;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.loaded = false;
                        reject(error);
                    });
            });
        },
    },
});
