import ServerError from "./ServerError";
import type ResponseData from "./ResponseData";
import type ValidationViolation from "./ValidationViolation";

export default class ValidationServerError extends ServerError {
    constructor(
        response: Response,
        responseData: ResponseData
    ) {
        super('ValidationServerError', response, responseData);
    }

    public getViolations(): Array<ValidationViolation> {
        if (this.responseData['violations'] === undefined) {
            return [];
        }

        return this.responseData['violations'];
    }

    public toString(): string {
        let violations: string = ``;

        this.getViolations().map((violation: ValidationViolation) => {
            violations += `<div>- <b>${violation.propertyPath}:</b> ${violation.message}</div>`;
        });

        return violations;
    }
}
