export default class Filters
{
    public page: number = 1;
    public workingLanguages: string[] = [];
    public onlyEmpty: boolean = false;
    public searchParameter: string = 'key';
    public searchString: string = '';
    public orderBy: string = 'key';
    public orderDirection: string = 'asc';
    public itemsPerPage: number = 10;
    public pagination: boolean = true;

    public createQuery() {
        let query: any = {};
        query.order = [];
        query.order[this.orderBy] = this.orderDirection;

        if (('' !== this.searchString) && ('' !== this.searchParameter)) {
            query[this.searchParameter] = this.searchString;
        }

        if (this.pagination) {
            query.page = this.page;
            query.itemsPerPage = this.itemsPerPage;
        } else {
            query.pagination = false;
        }

        return query;
    }
}
