import type Language from "../../stores/translation/Language";

const languages: Array<Language> = [
    {
        locale: 'hu',
        titleEn: 'Hungarian',
        title: 'Magyar',
        flag: 'hu.svg'
    },
    {
        locale: 'en',
        titleEn: 'English',
        title: 'English',
        flag: 'uk.svg'
    },
    {
        locale: 'de',
        titleEn: 'German',
        title: 'Deutsche',
        flag: 'de.svg'
    },
    {
        locale: 'ro',
        titleEn: 'Romanian',
        title: 'Română',
        flag: 'ro.svg'
    },
    {
        locale: 'fr',
        titleEn: 'French',
        title: 'Français',
        flag: 'fr.svg'
    },
    {
        locale: 'sk',
        titleEn: 'Slovak',
        title: 'Slovák',
        flag: 'sk.svg'
    },
    {
        locale: 'sr',
        titleEn: 'Serbian',
        title: 'Srpski',
        flag: 'sr.svg'
    },
    {
        locale: 'cs',
        titleEn: 'Czech',
        title: 'Čeština',
        flag: 'cs.svg'
    },
    {
        locale: 'pl',
        titleEn: 'Polish',
        title: 'Polskie',
        flag: 'pl.svg'
    }
];

export default languages;
