import { type NumberFormats }  from "@intlify/core-base/dist/core-base.d.ts";

const numberFormats: NumberFormats = {
    'hu-HU': {
        currency: {
            style: 'currency', currency: 'HUF', useGrouping: true, maximumFractionDigits: 0, notation: "standard"
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        roundedDecimal: {
            style: 'decimal', maximumFractionDigits: 0
        },
        percent: {
            style: 'percent', useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2
        }
    },
    'en-US': {
        currency: {
            style: 'currency', currency: 'USD', useGrouping: true, maximumFractionDigits: 2, notation: "standard"
        },
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        roundedDecimal: {
            style: 'decimal', maximumFractionDigits: 0
        },
        percent: {
            style: 'percent', useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2
        }
    }
};

export default numberFormats;
