import {defineStore} from "pinia";
import fetch from "../../common/utils/fetch";
import type ShopResponseType from "../../common/utils/ShopResponseType";
import {type Router} from "vue-router";
import type SiteStateInterface from "./SiteState";
import type SiteQuery from "./SiteQuery";
import { type SiteInterface } from "../../models/Site";

export const useSiteStore = defineStore('useSiteStore', {
    state: (): SiteStateInterface => ({
        loading: false,
        sites: []
    }),
    getters: {
    },
    actions: {
        getSites(router: Router, query: SiteQuery = {}): Promise<ShopResponseType> {
            this.loading = true;

            query.pagination = 0;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'site_api', query: query }).href)
                    .then((response: ShopResponseType) => {
                        const sites: SiteInterface[] = [];

                        if (Array.isArray(response['hydra:member'])) {
                            response['hydra:member'].forEach(element => {
                                if (element['@type'] !== 'Site') {
                                    return;
                                }
                                sites.push(element);
                            });
                        }

                        this.sites = sites;
                        this.loading = false;
                        resolve(response);
                    })
                    .catch(error => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },
    },
})
