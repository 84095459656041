export enum UserPermissionEnum {
    USER_READ = 'USER_READ',
    USER_WRITE = 'USER_WRITE',
}

export enum UserGroupPermissionEnum {
    USER_GROUP_READ = 'USER_GROUP_READ',
    USER_GROUP_WRITE = 'USER_GROUP_WRITE'
}

export enum TranslationPermissionEnum {
    TRANSLATION_READ = 'TRANSLATION_READ',
    TRANSLATION_WRITE = 'TRANSLATION_WRITE',
}

export enum LogPermissionEnum {
    LOG_READ = 'LOG_READ',
    LOG_WRITE = 'LOG_WRITE',
}
