<template>
    <div class="w-full 2xl:container mx-auto p-4 flex flex-col">
        <div class="mb-2 grid grid-cols-2">
            <div class="mt-1 flex space-x-4">
                <span class="block font-bold">{{Translator.trans('select_languages', 'Select languages', 'translations')}}:</span>
                <label v-for="lang in translationStore.languages" :key="lang.locale" class="block relative w-6 cursor-pointer">
                    <input type="checkbox" @change="workingLanguageSelected"
                           name="workingLanguages[]" :value="lang.locale"
                           v-model="translationStore.filters.workingLanguages"
                           class="hidden"
                    >

                    <span v-if="translationStore.filters.workingLanguages.includes(lang.locale)"
                          class="text-shadow-black text-white absolute text-center font-bold w-full">{{
                            lang.locale
                        }}</span>
                    <img :src="'/images/flags/' + lang.flag" :alt="'flag ' + lang.title" :title="lang.title">
                </label>
            </div>

            <div v-if="translationStore.filters.workingLanguages.length" class="mt-1">
                <button class="mx-auto btn btn-rounded btn-primary btn-danger" type="button" @click="writeOutTranslations($event)">{{Translator.trans('write_out_current_translations', 'Write out current translations', 'translations')}}</button>
            </div>
        </div>
        <div class="mt-2" v-if="!translationStore.filters.workingLanguages.length">
            {{ Translator.trans(
                'flag_helper_text',
                'Please select first, which languages you want to translate or watch.',
                'translations'
            ) }}
        </div>

        <div v-if="translationStore.filters.workingLanguages.length">
            <responsive-table :items="translationStore.translations" :fields="fields" condensed>

                <template #header>
                    <label for="search"
                           class="px-4 py-2 inline-block h-full leading-none bg-gray-darker text-white"
                    >{{ Translator.trans('search', 'Search', 'translations') }}</label>
                    <select
                        v-model="translationStore.filters.searchParameter"
                        class="inline-block h-full bg-light-gray text-black p-1 leading-none"
                        @change="search"
                    >
                        <option class="inline-block" value="key">{{ Translator.trans('key', 'Key', 'translations') }}</option>
                        <option class="inline-block" value="context">{{ Translator.trans('context', 'Context', 'translations') }}</option>
                        <option class="inline-block"
                                v-for="lang in translationStore.filters.workingLanguages" :key="lang" :value="lang"
                        >{{ Translator.trans(lang, lang, 'translations') }}
                        </option>
                    </select>
                    <input type="text" id="search" @change="search"
                           v-model="translationStore.filters.searchString" class="inline-block h-full p-1 text-black leading-none"
                    >
                </template>

                <template #head-context>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('context', 'Context', 'translations')"
                        @click="order('context')"/>
                </template>

                <template #head-key>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('key', 'Key', 'translations')"
                        @click="order('key')"/>
                </template>

                <template #head-hu>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('hu', 'HU', 'translations')"
                        @click="order('hu')"/>
                </template>

                <template #head-en>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('en', 'EN', 'translations')"
                        @click="order('en')"/>
                </template>

                <template #head-de>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('de', 'DE', 'translations')"
                        @click="order('de')"/>
                </template>

                <template #head-ro>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('ro', 'RO', 'translations')"
                        @click="order('ro')"/>
                </template>

                <template #head-fr>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('fr', 'FR', 'translations')"
                        @click="order('fr')"/>
                </template>

                <template #head-sk>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('sk', 'SK', 'translations')"
                        @click="order('sk')"/>
                </template>

                <template #head-sr>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('sr', 'SR', 'translations')"
                        @click="order('sr')"/>
                </template>

                <template #head-cs>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('cs', 'CS', 'translations')"
                        @click="order('cs')"/>
                </template>

                <template #head-pl>
                    <translation-filter-button
                        :filters="translationStore.filters"
                        :label="Translator.trans('pl', 'PL', 'translations')"
                        @click="order('pl')"/>
                </template>

                <template #cell-hu="{item: item}">
                    <shop-input border v-model="(item as TranslationInterface).hu" @change="updateTranslation(item as TranslationInterface, 'hu', $event)"/>
                </template>

                <template #cell-en="{item: item}">
                    <shop-input border v-model="(item as TranslationInterface).en" @change="updateTranslation(item as TranslationInterface, 'en', $event)"/>
                </template>

                <template #cell-de="{item: item}">
                    <shop-input border v-model="(item as TranslationInterface).de" @change="updateTranslation(item as TranslationInterface, 'de', $event)"/>
                </template>

                <template #cell-ro="{item: item}">
                    <shop-input border v-model="(item as TranslationInterface).ro" @change="updateTranslation(item as TranslationInterface, 'ro', $event)"/>
                </template>

                <template #cell-fr="{item: item}">
                    <shop-input border v-model="(item as TranslationInterface).fr" @change="updateTranslation(item as TranslationInterface, 'fr', $event)"/>
                </template>

                <template #cell-sk="{item: item}">
                    <shop-input border v-model="(item as TranslationInterface).sk" @change="updateTranslation(item as TranslationInterface, 'sk', $event)"/>
                </template>

                <template #cell-sr="{item: item}">
                    <shop-input border v-model="(item as TranslationInterface).sr" @change="updateTranslation(item as TranslationInterface, 'sr', $event)"/>
                </template>

                <template #cell-cs="{item: item}">
                    <shop-input border v-model="(item as TranslationInterface).cs" @change="updateTranslation(item as TranslationInterface, 'cs', $event)"/>
                </template>

                <template #cell-pl="{item: item}">
                    <shop-input border v-model="(item as TranslationInterface).pl" @change="updateTranslation(item as TranslationInterface, 'pl', $event)"/>
                </template>

                <template v-slot:footer>
                    <div class="pager mt-2 flex justify-center space-x-4">
                        <button class="btn btn-rounded btn-primary" type="button"
                                @click="viewPage(translationStore.filters.page - 1)">{{ Translator.trans('previous', 'Previous', 'translations') }}</button>
                        <label class="current font-bold border px-2 py-1 flex items-center">{{ Translator.trans('page', 'Page', 'translations') }}: <input
                            class="w-10 text-center"
                            type="text"
                            @keyup="validatePage($event)"
                            @keyup.enter="reloadPage()"
                            @blur="reloadPage()"
                            v-model="translationStore.filters.page"> /
                            {{ translationStore.totalPages }}</label>
                        <button class="btn btn-rounded btn-primary"
                                type="button"
                                @click="viewPage(translationStore.filters.page + 1)"
                        >{{ Translator.trans('next', 'Next', 'translations') }}</button>
                        <label class="ml-auto font-bold border px-2 py-1 flex items-center">{{ Translator.trans('items_per_page', 'Items per page', 'translations') }}: <input
                            class="text-center w-10" type="text"
                            @keyup="validateItemsPerPage($event)"
                            @keyup.enter="reloadPage()"
                            @blur="reloadPage()"
                            v-model="translationStore.filters.itemsPerPage"> / {{ translationStore.totalItems }}</label>
                    </div>
                </template>
            </responsive-table>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, type ComputedRef} from "vue";
import ResponsiveTable from "../../components/ResponsiveTable/ResponsiveTable.vue";
import type FieldDefinitionInterface from "../../components/ResponsiveTable";
import ShopInput from "../../components/Form/ShopInput.vue";
import ServerError from "../../common/error/ServerError";
import TranslationFilterButton from "./TranslationFilterButton.vue";
import {useToastStore} from "../../stores/toast/toastStore";
import {useTranslationStore} from "../../stores/translation/translationStore";
import {useRouter} from "vue-router";
import { Translator } from "../../common/i18n";
import type TranslationInterface from "../../models/Translation";
import { ToastTypeEnum } from "@/stores/toast/ToastInterface";

const router = useRouter();
const translationStore = useTranslationStore();
const toastStore = useToastStore();

const fields: ComputedRef<FieldDefinitionInterface[]> = computed(() => {
    let fields: FieldDefinitionInterface[] = [
        {
            key: "id",
            label: Translator.trans('id', "ID", 'translations') ?? "ID",
            value: "id",
        },
        {
            key: "context",
            label: Translator.trans('context', "Context", 'translations') ?? "Context",
            value: "context",
        },
        {
            key: "key",
            label: Translator.trans('key', "Key", 'translations') ?? "Key",
            value: "key",
        },
        {
            key: "en",
            label: "en",
            value: "en",
        },
        {
            key: "hu",
            label: "hu",
            value: "hu",
        },
        {
            key: "de",
            label: "de",
            value: "de",
        },
        {
            key: "ro",
            label: "ro",
            value: "ro",
        },
        {
            key: "fr",
            label: "fr",
            value: "fr",
        },
        {
            key: "sr",
            label: "sr",
            value: "sr",
        },
        {
            key: "sk",
            label: "sk",
            value: "sk",
        },
        {
            key: "cs",
            label: "cs",
            value: "cs",
        },
        {
            key: "pl",
            label: "pl",
            value: "pl",
        },
    ];

    fields.forEach((column: FieldDefinitionInterface) => {
        if (!['key', 'context'].includes(column.key)) {
            column.visible = translationStore.filters.workingLanguages.includes(column.key);
        }
    });

    return fields;
});

const viewPage = (page: number) => {
    if (1 > page) {
        page = 1;
    }
    translationStore.filters.page = page;
    translationStore.loadByFilters(router)
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
};

const validatePage = (event: KeyboardEvent) => {
    if (translationStore.filters.page) {
        let page: number = Number(translationStore.filters.page.toString().replace(/[^0-9]/g, ''));
        if (translationStore.totalPages < page) {
            page = translationStore.totalPages;
        }
        translationStore.filters.page = page;
    }
};

const validateItemsPerPage = (event: KeyboardEvent) => {
    if (translationStore.filters.itemsPerPage) {
        translationStore.filters.itemsPerPage = Number(translationStore.filters.itemsPerPage.toString().replace(/[^0-9]/g, ''));
        translationStore.filters.page = 1;
    }
};

const updateTranslation = (translation: TranslationInterface, field: string, event: Event) => {
    if (event.target instanceof HTMLInputElement) {
        let element = event.target;
        if (element.value === '') {
            toastStore.addErrorToast(
                Translator.trans(
                    'translation_cant_be_empty',
                    "Translation cant be empty",
                    'translations')
                ?? 'Translation cant be empty!'
            );

            return;
        }

        element.style.boxShadow = 'inset 0px 0px 5px orange';
        element.disabled = true;
        let item: any = {id: translation.id};
        item[field] = element.value;
        translationStore.updateTranslation(router, item)
            .then(() => {
                element.style.boxShadow = 'inset 0px 0px 5px green';
                setTimeout(() => {
                    element.style.boxShadow = 'none';
                    element.disabled = false;
                }, 3000);
                toastStore.addToast({
                    title: Translator.trans(
                        'text_saved',
                        `Text saved: "{key}"`,
                        'translations',
                        {key: element.value}
                    ) ?? `Text saved:  "${element.value}"`,
                    type: ToastTypeEnum.Success,
                    showIcon: false,
                    showClose: false,
                    timer: 3,
                });
            })
            .catch((error: ServerError) => {
                element.style.boxShadow = 'inset 0px 0px 5px red';
                element.disabled = false;
                toastStore.addErrorToast(`Text not saved: ${error.toString()}`);
            });
    }
};

const order = (field: string) => {
    if (field === translationStore.filters.orderBy) {
        // actually this field ordered, so toggle the direction
        if ('asc' === translationStore.filters.orderDirection) {
            translationStore.filters.orderDirection = 'desc';
        } else {
            translationStore.filters.orderDirection = 'asc';
        }
    } else {
        translationStore.filters.orderBy = field;
        translationStore.filters.orderDirection = 'asc';
    }
    translationStore.loadByFilters(router)
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
};

const workingLanguageSelected = () => {
    if (translationStore.filters.workingLanguages.length) {
        translationStore.loadByFilters(router)
            .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
    }
};

const search = () => {
    translationStore.loadByFilters(router)
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
};

const writeOutTranslations = (event: Event) => {
    if (event.target instanceof HTMLButtonElement) {
        let button = event.target;
        button.disabled = true;
        translationStore.writeOutTranslations(router)
            .then((response) => {
                toastStore.addToast({
                    title: Translator.trans(
                            'export_complete',
                            "Export complete!",
                            'translations')
                        ?? 'Export complete!',
                    type: ToastTypeEnum.Success,
                    icon: "marso-icon-right",
                    showClose: false,
                    timer: 5,
                });
                button.disabled = false;
            })
            .catch((error: ServerError) => {
                toastStore.addErrorToast(`Export failed: ${error.toString()}`);
                button.disabled = false;
            });
    }
}

const reloadPage = () => {
    translationStore.loadByFilters(router)
        .catch((error: ServerError) => toastStore.addErrorToast(error.toString()));
};
</script>
