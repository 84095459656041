<template>
    <router-view v-if="!hasDefaultLayout" />

    <div v-if="hasDefaultLayout" class="main">
        <navbar v-if="securityStore.authenticated"></navbar>

        <router-view v-slot="{ Component }: { Component: any }" :class="isLoginPage ? 'app-content-login' : 'app-content'">
            <transition name="fade" mode="out-in">
                <component v-if=" securityStore.authenticated || isLoginPage " :is="Component" />
            </transition>
        </router-view>

        <toasts />
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onUpdated, type ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Navbar from '../components/Layout/Navbar.vue';
import { Translator } from '../common/i18n';
import Toasts from "../components/Layout/Toasts.vue";
import ServerError from "../common/error/ServerError";
import { useSecurityStore } from "../stores/security/securityStore";
import { useTranslationStore } from '@/stores/translation/translationStore';
import { useConfigurationStore } from '@/stores/configuration/configurationStore';

const configurationStore = useConfigurationStore();
const translationStore = useTranslationStore();
const route = useRoute();
const router = useRouter();
const securityStore = useSecurityStore();

const isLoginPage = computed(() => 'app_login' === router.currentRoute.value.name);

// const tokenExpirationInEpochSeconds: ComputedRef<number> = computed(() => {
//     if (securityStore.authenticatedUser === null) {
//         return 0;
//     }

//     return securityStore.authenticatedUser.exp;
// });

const hasDefaultLayout: ComputedRef<boolean> = computed(() => {
    if (route.meta.hasDefaultLayout === undefined) {
        return true;
    }

    if (typeof route.meta.hasDefaultLayout !== 'boolean') {
        return true;
    }

    return (typeof route.meta.hasDefaultLayout === 'boolean') && Boolean(route.meta.hasDefaultLayout);
})

onBeforeMount(async () => {
    try {
        await securityStore.refreshToken(router);
        await configurationStore.load(router);
        const html = document.querySelector('html');
        if (html !== null) {
            html.dataset.env = configurationStore.configuration.env;
        }
        await translationStore.changeLocale(router, document.querySelector('html')?.getAttribute('lang') || 'en');

        setInterval(() => {
            // let nowInEpochSeconds: number = Math.floor(Date.now() / 1000);

            // refresh token only in 120 seconds
            // if (tokenExpirationInEpochSeconds.value - nowInEpochSeconds < 120) {
                securityStore.refreshToken(router)
                    .catch(() => {
                        router.push({ name: 'app_login' });
                    });
            // }
        }, 90000);

        const redirect = router.resolve(route.query.redirect ? `${route.query.redirect}` : route);

        if (redirect.name === route.name) {
            return;
        }

        if (redirect.name) {
            await router.replace({ name: redirect.name });
            return;
        }

    } catch (error: any) {

        if (error instanceof ServerError
            && error.responseData.redirect
            && error.responseData.redirect.length
            && route.name !== router.resolve(error.responseData.redirect).name) {
            // checkAuth fails
            await router.push(error.responseData.redirect);
        }

        if (route.name !== 'app_login') {
            // refreshToken fails or other error
            await router.push({ name: 'app_login' });

            return;
        }
    }
});

onUpdated(() => {
    let title: string = route.meta.title
        ? Translator.trans(
            // @ts-expect-error
            route.meta.title.toLowerCase(),
            // @ts-expect-error
            route.meta.title,
            'app'
        )
        : '';
    window.document.title = `${title} - MARSO`;
});
</script>

<style lang="scss">
$navHeight: 56px;

.app-content {
    min-height: calc(100vh - $navHeight);
    position: relative;
    top: $navHeight;
}

html[data-feature-flag-scrollbar] {
    .app-content {
        top: 0;
    }
}

.app-content-login {
    min-height: 100vh;
    padding-bottom: $navHeight;
}

@media screen and (max-width: 1024px) {
    .app-content-login {
        min-height: 100vh;
        padding-bottom: $navHeight;
    }
}
</style>
