import DocumentSigner from '@/pages/documentSigner/DocumentSigner.vue';

export default [
    {
        name: 'home',
        path: '/',
        component: DocumentSigner,
        meta: { requiresAuth: true, title: 'PDF Signer' }
    },
    {
        name: 'documents_api',
        path: '/api/documents/:id(\\d+)?',
        redirect: '',
        meta: { requiresAuth: true }
    }
];
