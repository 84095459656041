export enum DocumentStatusEnum {
    pending = 'pending',
    signed = 'signed',
    rejected = 'rejected',
    accepted = 'accepted',
}

export interface DocumentInterface {
    '@id': string;
    '@type': string;
    '@context': string;
    id: string;
    createdAt: string;
    timeout: number;
    expiresAt: string;
    document: string;
    signedDocument?: string;
    userExternalId?: string;
    site: string;
    group?: string;
    webhook?: string;
    documentName: string;
    receivingEmail?: string;
    note?: string;
    status: DocumentStatusEnum;
    displayOnly: boolean;
}
