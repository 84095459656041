import DropdownInputItem from "../Form/DropdownInput/DropdownInputItem";

export default interface FieldDefinitionInterface {
    key: string;
    label: string;
    value?: string | ((item: any) => string) | Array<DropdownInputItem>;
    visible?: boolean;
    filterable?: boolean;
    filterValue?: string;
    sortable?: boolean;
    sortingFunction?: ((itemA: any, itemB: any) => number);
}

export function isFieldDefinition(arg: unknown): arg is FieldDefinitionInterface {
    return typeof arg === 'object'
        && arg !== null
        && Object.prototype.hasOwnProperty.call(arg, 'key')
        && Object.prototype.hasOwnProperty.call(arg, 'label');
}
