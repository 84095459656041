import ServerError from "./ServerError";
import type ResponseData from "./ResponseData";

export default class InternalServerError extends ServerError {
    constructor(
        response: Response,
        responseData: ResponseData
    ) {
        super('InternalServerError', response, responseData);
    }
}
