<template>
    <div
        v-if="!dismissed"
        class="text-white p-4 border-0 rounded flex flex-row flex-nowrap items-center justify-between gap-4"
        :class="{
            'bg-red': variant === 'danger',
            'bg-peach': variant === 'warning',
            'bg-green': variant === 'success',
            'bg-blue': variant === 'info',
        }"
    >
        <div class="flex items-center gap-4">
            <span class="text-lg inline-block align-middle" v-if="icon">
                <i :class="{ [iconClass]: true }" />
            </span>
            <span class="inline-block align-middle">
                <strong
                    v-if="title"
                    class="block"
                >{{ title }}</strong>
                <span v-if="message" v-html="message"></span>
                <slot v-else name="message"></slot>
            </span>
        </div>
        <button
            v-if="dismissable"
            class="bg-transparent text-2xl font-semibold leading-none outline-none focus:outline-none"
            @click="onDismissed"
        >
            <span>×</span>
        </button>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
    title: {
        type: String,
        required: false,
    },
    message: {
        type: String,
    },
    icon: {
        type: Boolean,
        required: false,
        default: false,
    },
    iconClass: {
        type: String,
        required: false,
        default: "marso-icon-notification",
    },
    dismissable: {
        type: Boolean,
        required: false,
        default: false,
    },
    variant: {
        type: String,
        required: false,
        default: "danger",
        validator(value: string) {
            return ["danger", "warning", "success", "info"].includes(value);
        },
    },
});
const emit = defineEmits(["dismissed"]);

const dismissed = ref(false);

const onDismissed = () => {
    dismissed.value = true;

    emit("dismissed");
};
</script>
