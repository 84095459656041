import { ToastPosition } from './ToastPosition';

export enum ToastTypeEnum {
    Danger = 'danger',
    Warning = 'warning',
    Success = 'success',
    Info = 'info',
}

export default interface ToastInterface {
    id?: number;
    active?: boolean;
    title?: string;
    message?: string;
    messageHtml?: string;
    showIcon?: boolean;
    icon?: string;
    showClose?: boolean;
    type?: ToastTypeEnum;
    timer?: number;
    position?: ToastPosition;
}
