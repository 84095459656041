<template>
    <div
        v-for="position in positions"
        :key="position"
        class="fixed z-50 flex flex-col gap-2"
        :class="[getToastPosition(position)]"
    >
        <template
            v-for="toast in toastStore.toasts[position]"
            :key="toast.id"
        >
            <div
                v-if="toast.active"
                class="text-white p-4 border-0 rounded flex flex-row flex-nowrap items-center gap-4 mb-2 justify-between shadow"
                :class="{
                    'bg-red': toast.type === 'danger',
                    'bg-peach': toast.type === 'warning',
                    'bg-green': toast.type === 'success',
                    'bg-blue': toast.type === 'info',
                }"
            >
                <div class="flex items-center gap-4">
                    <span
                        v-if="toast.showIcon"
                        class="text-lg inline-block align-middle"
                    >
                        <i :class="toast.icon" />
                    </span>
                    <span class="inline-block align-middle">
                        <strong
                            v-if="toast.title"
                            class="block"
                        >{{ toast.title }}</strong>
                        <span v-if="toast.messageHtml" v-html="toast.messageHtml"></span>
                        <span v-else-if="toast.message">{{ toast.message }}</span>
                    </span>
                </div>
                <button
                    v-if="toast.showClose"
                    class="bg-transparent text-2xl font-semibold leading-none outline-none focus:outline-none"
                    @click="removeToast(toast.id)"
                >
                    <span>×</span>
                </button>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import {ToastPosition} from "../../stores/toast/ToastPosition";
import {useToastStore} from "../../stores/toast/toastStore";

const toastStore = useToastStore();
const positions: Array<string> = Object.values(ToastPosition);

const removeToast = (id: number| undefined) => {
    if (id === undefined) {
        return;
    }

    toastStore.removeToast(id);
};

const mobilePositon = "bottom-4 w-full px-4 md:w-auto md:px-0";

const getToastPosition = (position: string) => {
    switch (position) {
        case ToastPosition.BottomRight:
            return `${mobilePositon} md:bottom-8 md:right-8`;
        case ToastPosition.TopRight:
            return `${mobilePositon} md:top-8 md:right-8`;
        case ToastPosition.BottomLeft:
            return `${mobilePositon} md:bottom-8 md:left-8`;
        case ToastPosition.TopLeft:
            return `${mobilePositon} md:top-8 md:left-8`;
    }
};
</script>
