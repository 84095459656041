<template>
    <div>
        <div class="pdf-container" :class="{'pdf-loading': isLoading}">
            <img
                v-if="documentSignerStore.currentDocument === null || !configurationStore.loaded || isLoading"
                id="loading-spinner"
                src="/images/loading.svg"
                alt="Loading"
                class="mx-auto" />

            <div v-if="documentSignerStore.currentDocument === null || !configurationStore.loaded" class="flex w-full items-center align-middle flex-col">
                <div class="alert">
                    {{ Translator.trans('waiting', 'Waiting for document...', 'signer') }}
                </div>
            </div>

            <div v-else class="pdf" ref="pdfView" :class="{'touch-none': documentSignerStore.drawMode}">
                <div id="viewerContainer" tabindex="0" ref="viewerContainer">
                    <div id="viewer" class="pdfViewer" ref="viewer">
                        <div id="pagesContainer" ref="pagesContainer" />
                    </div>
                </div>
            </div>
        </div>

        <div class="fixed left-4 bottom-8 flex flex-col items-center gap-4 text-4xl text-white" v-if="documentSignerStore.currentDocument">
            <button
                v-if="!featureFlagScrollbar && !documentSignerStore.currentDocument.displayOnly"
                type="button"
                :title="Translator.trans('draw', 'Draw', 'signer')"
                :class="{
                    'shadow-inner bg-gray-darker bg-opacity-25 outline outline-4 outline-red': documentSignerStore.drawMode,
                }"
                @click="documentSignerStore.toggleDrawing()"
                class="bg-gray-dark p-4 rounded"
            >
                <i class="marso-icon-pencil" />
            </button>
            <button
                v-if="!documentSignerStore.currentDocument.displayOnly"
                type="button"
                :title="Translator.trans('undo_all', 'Undo all', 'signer')"
                @click="documentSignerStore.undoAll"
                class="bg-orange p-4 rounded"
            >
                <i class="marso-icon-refresh" />
            </button>
            <button
                v-if="!documentSignerStore.currentDocument.displayOnly"
                type="button"
                :title="Translator.trans('undo', 'Undo', 'signer')"
                @click="documentSignerStore.undo"
                class="bg-peach-light p-4 rounded"
            >
                <i class="marso-icon-undo" />
            </button>

            <button
                type="button"
                v-if="documentSignerStore.zoomValue > 0.9"
                :title="Translator.trans('zoom_out', 'Zoom out', 'signer')"
                @click="documentSignerStore.zoomOut()"
                class="bg-blue p-4 rounded"
            >
                <i class="marso-icon-zoom-out" />
            </button>
            <button
                type="button"
                :title="Translator.trans('zoom_in', 'Zoom in', 'signer')"
                @click="documentSignerStore.zoomIn()"
                class="bg-blue p-4 rounded"
            >
                <i class="marso-icon-zoom-in" />
            </button>
            <button
                type="button"
                :title="Translator.trans('reject', 'Reject', 'signer')"
                @click="documentSignerStore.reject(documentStore)"
                class="bg-red p-4 rounded"
            >
                <i class="marso-icon-close" />
            </button>

            <button
                v-if="!documentSignerStore.currentDocument.displayOnly"
                type="button"
                :title="Translator.trans('sign', 'Sign', 'signer')"
                @click="documentSignerStore.sign(documentStore)"
                class="bg-green p-4 rounded"
            >
                <i class="marso-icon-done" />
            </button>
            <button
                v-else
                type="button"
                :title="Translator.trans('accept', 'Accept', 'signer')"
                @click="documentSignerStore.accept(documentStore)"
                class="bg-green p-4 rounded"
            >
                <i class="marso-icon-done" />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { onMounted, ref, watch } from 'vue';
import type { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import debounce from 'lodash/debounce';
import { useDocumentStore } from '@/stores/document/documentStore';
import { AnnotationPath, AnnotationVector, type AnnotationPathInterface, type AnnotationVectorInterface, Coordinate } from './utils';
import { useConfigurationStore } from '@/stores/configuration/configurationStore';
import { Translator } from '@/common/i18n';
import { useDocumentSignerStore } from '@/stores/document/documentSignerStore';
import { useRouter } from 'vue-router';
import { useToastStore } from '@/stores/toast/toastStore';
import { ToastTypeEnum } from '@/stores/toast/ToastInterface';

const router = useRouter();
const configurationStore = useConfigurationStore();
const documentStore = useDocumentStore();
const documentSignerStore = useDocumentSignerStore();
const toastStore = useToastStore();

const featureFlagScrollbar = ref(false);

onMounted(() => {
    featureFlagScrollbar.value = typeof document.querySelector('html')?.dataset.featureFlagScrollbar !== 'undefined';
    documentSignerStore.drawMode = featureFlagScrollbar.value;
});

configurationStore.load(router).then(() => {
    documentStore.registerListener();
});

GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.8.162/pdf.worker.min.js';

const isLoading = ref(false);

const pdfView = ref<HTMLDivElement | null>(null);
watch(() => pdfView.value, () => {
    documentSignerStore.pdfView = pdfView.value;
});

const pagesContainer = ref();
const viewer = ref<HTMLDivElement | null>(null);
watch(() => viewer.value, () => {
    documentSignerStore.viewer = viewer.value;
});

watch(() => documentSignerStore.zoomValue, () => {
    if (documentSignerStore.pdfView === null) {
        return;
    }
    isLoading.value = true;
    documentSignerStore.pdfView.style.width = 100 * documentSignerStore.zoomValue + '%';
    documentSignerStore.needResize = true;
    window.dispatchEvent(new Event('resize'));
});

watch(() => documentSignerStore.currentDocument, (document) => {
    if (pagesContainer.value !== undefined && pagesContainer.value !== null) {
        pagesContainer.value.textContent = '';
    }
    annotationLayers = {};
    documentSignerStore.annotationPathsStack.replaceAll([]);
});

const viewerContainer = ref<HTMLElement>();

// Tárolja az összes annotációs canvas elemet az oldalszám alapján
let annotationLayers: Record<number, CanvasRenderingContext2D> = {};

// Tárolja az aktív rajzolás információit
let isDrawing = false;
let path: AnnotationPathInterface | undefined = undefined;
let drawStartX = 0;
let drawStartY = 0;
let bodyWidth = 0;
let bodyHeight = 0;

watch(() => documentStore.documents.length, (queueLength) => {
    if (queueLength === 0) {
        return;
    }

    if (documentSignerStore.currentDocument === null) {
        documentSignerStore.currentDocument = documentStore.documents.shift() ?? null;
    }
});

let loadedPdf: PDFDocumentProxy;

watch(() => documentSignerStore.currentDocument, () => {
    if (documentSignerStore.currentDocument === null) {
        isLoading.value = false;
        return;
    }

    // add expiresAt watcher
    const expiresAt = new Date(documentSignerStore.currentDocument.expiresAt);
    let timeout = expiresAt.getTime() - Date.now();
    // drop expired documents
    if (timeout <= 0) {
        documentSignerStore.currentDocument = null;
        isLoading.value = false;
        return;
    }

    // do not allow more than 1 hour timeout
    if (timeout > 3600000) {
        timeout = 3600000;
    }
    setTimeout(() => {
        if (documentSignerStore.currentDocument === null) {
            return;
        }

        toastStore.addToast({
            type: ToastTypeEnum.Warning,
            message: Translator.trans(
                'expired',
                'The time allowed for signing document `{document}` has expired.',
                'signer',
                { document: documentSignerStore.currentDocument.documentName }
            ),
            timer: 0
        });

        documentSignerStore.currentDocument = null;
    }, timeout);

    isLoading.value = true;

    const loadingTask = getDocument({ data: atob(documentSignerStore.currentDocument.document) });

    loadingTask.promise.then(async (pdf: PDFDocumentProxy) => {
        if (loadedPdf) {
            loadedPdf.destroy();
        }
        loadedPdf = pdf;

        // Oldalak számának lekérdezése
        const numPages = loadedPdf.numPages;

        // Az összes oldal betöltése
        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
            let page = await loadedPdf.getPage(pageNumber);

            if (typeof viewerContainer.value === 'undefined' || viewerContainer.value === null) {
                return;
            }
            const defaultWidth = page.getViewport({ scale: 1 }).width;
            const scale = viewerContainer.value.getBoundingClientRect().width / defaultWidth;
            const viewport = page.getViewport({ scale });

            // Oldal tartályának létrehozása
            const pageContainer = document.createElement('div');
            pageContainer.className = 'pageContainer';
            pagesContainer.value.appendChild(pageContainer);

            // Canvas létrehozása a PDF oldalhoz
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            if (!(context instanceof CanvasRenderingContext2D)) {
                return;
            }
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            pageContainer.appendChild(canvas);

            // Annotation Layer létrehozása
            const annotationLayer = document.createElement('div');
            annotationLayer.className = 'annotationLayer';
            pageContainer.appendChild(annotationLayer);

            // Az Annotation Layer canvasának létrehozása
            const annotationCanvas = document.createElement('canvas');
            const annotationContext = annotationCanvas.getContext('2d');
            annotationCanvas.height = viewport.height;
            annotationCanvas.width = viewport.width;
            annotationLayer.appendChild(annotationCanvas);

            // Oldal kirajzolása a canvason
            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };

            const renderTask = page.render(renderContext);

            // Annotation Layer canvasának hozzáadása az annotációs rétegekhez
            if (annotationContext !== null) {
                annotationLayers[pageNumber] = annotationContext;
            }

            await renderTask.promise;

            // Rajzolás eseménykezelők hozzáadása
            annotationCanvas.addEventListener('mousedown', startDrawing);
            annotationCanvas.addEventListener('mousemove', draw);
            annotationCanvas.addEventListener('mouseup', stopDrawing);

            annotationCanvas.addEventListener('touchstart', startDrawing, false);
            annotationCanvas.addEventListener('touchmove', draw, false);
            annotationCanvas.addEventListener('touchend', stopDrawing, false);
        }

        if (bodyWidth === 0) {
            bodyWidth = window.document.body.getBoundingClientRect().width;
        }
        if (bodyHeight === 0) {
            bodyHeight = window.document.body.getBoundingClientRect().height;
        }

        isLoading.value = false;


        // Nem szeparálható a loadingTask promiseból, különben hibát dob
        window.addEventListener('resize', debounce(async () => {
            let newWidth = window.document.body.getBoundingClientRect().width;
            let newHeight = window.document.body.getBoundingClientRect().height;
            if (!documentSignerStore.needResize && newWidth === bodyWidth && newHeight === bodyHeight) {
                return;
            }
            bodyWidth = newWidth;
            bodyHeight = newHeight;

            isLoading.value = true;
            const numPages = loadedPdf.numPages;
            for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
                let page = await loadedPdf.getPage(pageNumber);
                if (typeof viewerContainer.value === 'undefined' || viewerContainer.value === null) {
                    return;
                }
                const defaultWidth = page.getViewport({ scale: 1 }).width;
                const scale = viewerContainer.value.getBoundingClientRect().width / defaultWidth;
                const viewport = page.getViewport({ scale });
                const canvas = pagesContainer.value.querySelectorAll('.pageContainer > canvas')[pageNumber - 1] as HTMLCanvasElement;
                const annotation = annotationLayers[pageNumber].canvas;

                if (canvas === null || annotation === null) {
                    return;
                }

                const drawScale = annotation.width / viewport.width;

                canvas.width = viewport.width;
                canvas.height = viewport.height;
                annotation.width = viewport.width;
                annotation.height = viewport.height;

                let renderTask = page.render({
                    canvasContext: canvas.getContext('2d')!,
                    viewport
                });

                await renderTask.promise;

                const annotationContext = annotation.getContext('2d')!;

                const paths = documentSignerStore.annotationPathsStack.getAll();

                paths.forEach((path: AnnotationPathInterface, pathIndex: number) => {
                    if (path.page !== pageNumber) {
                        return;
                    }

                    let vectors: AnnotationVectorInterface[] = path.path;
                    vectors.forEach((vector: AnnotationVectorInterface, vectorIndex: number) => {
                        vector.from.x /= drawScale;
                        vector.from.y /= drawScale;
                        vector.to.x /= drawScale;
                        vector.to.y /= drawScale;

                        vectors[vectorIndex] = vector;

                        drawPath(annotationContext, vector);
                    });

                    path.replaceAll(vectors);
                    paths[pathIndex] = path;
                });

                documentSignerStore.annotationPathsStack.replaceAll(paths);
            }
            isLoading.value = false;
        }, 300));
    });

    function drawPath(ctx: CanvasRenderingContext2D, path: AnnotationVectorInterface, color: string = 'darkblue', width: number = 2) {
        ctx.strokeStyle = color;
        ctx.lineWidth = width;
        ctx.beginPath();
        ctx.moveTo(path.from.x, path.from.y);
        ctx.lineTo(path.to.x, path.to.y);
        ctx.stroke();
    }

    // Rajzolás kezdete
    function startDrawing(event: MouseEvent | TouchEvent) {
        if (!documentSignerStore.drawMode) {
            return;
        }

        const target = event.target as HTMLCanvasElement;
        const rect = target.getBoundingClientRect();
        isDrawing = true;

        const pageNumber = getCurrentPageNumber(target);
        path = new AnnotationPath(pageNumber);

        if (event instanceof MouseEvent) {
            drawStartX = event.clientX - rect.left;
            drawStartY = event.clientY - rect.top;
        } else {
            drawStartX = event.touches[0].clientX - rect.left;
            drawStartY = event.touches[0].clientY - rect.top;
        }
    }

    // Rajzolás folytatása
    function draw(event: MouseEvent | TouchEvent) {
        if (!isDrawing || path === undefined) return;

        const target = event.target as HTMLCanvasElement;
        const rect = target.getBoundingClientRect();
        const pageNumber = getCurrentPageNumber(target);
        const annotationContext = annotationLayers[pageNumber];
        if (annotationContext === null) {
            return;
        }

        let x, y;
        if (event instanceof MouseEvent) {
            x = event.clientX - rect.left;
            y = event.clientY - rect.top;
        } else {
            x = event.touches[0].clientX - rect.left;
            y = event.touches[0].clientY - rect.top;
        }

        let vector = new AnnotationVector(new Coordinate(drawStartX, drawStartY), new Coordinate(x, y));
        path.push(vector);
        drawPath(annotationContext, vector);

        drawStartX = x;
        drawStartY = y;
    }

    // Rajzolás vége
    function stopDrawing() {
        isDrawing = false;
        if (path !== undefined) {
            documentSignerStore.annotationPathsStack.push(path);
            path = undefined;
        }
    }

    // Aktuális oldalszám lekérdezése
    function getCurrentPageNumber(target: EventTarget) {
        const pages = pagesContainer.value.getElementsByClassName('pageContainer');
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            if (page.contains(target as Node)) {
                return i + 1;
            }
        }
        return 0;
    }
});
</script>

<style lang="scss">
.pdf-container {

    &.pdf-loading {
        #loading-spinner {
            @apply absolute z-50;
            top: calc(50% - 157px / 2);
            left: calc(50% - 157px / 2);
        }

        .pdf::after {
            @apply block w-full h-full fixed top-0 left-0 backdrop-blur bg-gray bg-opacity-20 z-40 pointer-events-none;
            content: ' ';
            animation: fade 0.5s;
        }
    }

    .pdf {
        @apply ml-[5rem] p-4;
        width: 90%;

        #viewerContainer {
            @apply w-full;

            .pdfViewer {
                @apply w-full;

                canvas {
                    @apply w-full h-full object-contain;
                }
            }

            #pagesContainer {
                @apply flex flex-col gap-4;

                .pageContainer {
                    @apply relative w-full h-full shadow;
                    // box-shadow: 0 0 1rem #ccc;

                    .annotationLayer {
                        @apply absolute top-0 left-0 w-full h-full;
                    }
                }
            }
        }
    }

    .bottomBar {
        @apply sticky bottom-0 left-0 right-0 bg-red;
        height: 56px;
    }
}

html[data-feature-flag-scrollbar] {
    .pdf-container {
        @apply fixed left-0 right-0 overflow-auto;
        top: 56px;
        bottom: 56px;

        .pdf {
            @apply touch-none;
        }
    }
}
</style>
