import {defineStore, type Store} from "pinia";
import type ToastStateInterface from "./ToastState";
import type ToastInterface from "./ToastInterface";
import {ToastPosition} from "./ToastPosition";
import { ToastTypeEnum } from "./ToastInterface";

export type ToastStoreType = Store<
    "toastStore", // id
    ToastStateInterface, // state
    {}, // getters
    { //actions
        addToast(toast: ToastInterface): void;
        removeToast(id: number): void;
        addErrorToast(errorMessage: string): void;
    }
>;

export const useToastStore = defineStore('toastStore', {
    state: (): ToastStateInterface => ({
        toasts: {
            [ToastPosition.TopLeft]: [],
            [ToastPosition.TopRight]: [],
            [ToastPosition.BottomLeft]: [],
            [ToastPosition.BottomRight]: [],
        },
        incrementId: 0
    }),
    getters: {
    },
    actions: {
        addToast(toast: ToastInterface): void {
            const defaultToast: ToastInterface = {
                active: true,
                title: undefined,
                message: undefined,
                messageHtml: undefined,
                showIcon: true,
                icon: 'marso-icon-notification',
                showClose: true,
                type: ToastTypeEnum.Info,
                timer: 5,
                position: ToastPosition.TopRight,
            };

            const mergedToast: ToastInterface = Object.assign(defaultToast, toast);

            this.incrementId++;
            mergedToast.id = this.incrementId;

            if (mergedToast.position !== undefined) {
                this.toasts[mergedToast.position].push(mergedToast);
            }

            if (mergedToast.timer && 0 < mergedToast.timer) {
                if (mergedToast.id) {
                    setTimeout(
                        this.removeToast.bind(this, mergedToast.id),
                        mergedToast.timer * 1000
                    );
                }
            }
        },
        removeToast(id: number): void {
            for (const position of Object.keys(this.toasts)) {
                this.toasts[position] = this.toasts[position].filter((e: ToastInterface) => {
                    return e.id !== id;
                });
            }
        },
        addErrorToast(errorMessage: string): void {
            this.addToast({
                active: true,
                title: undefined,
                message: undefined,
                messageHtml: errorMessage,
                showIcon: true,
                icon: 'marso-icon-notification',
                showClose: true,
                type: ToastTypeEnum.Danger,
                timer: 5,
                position: ToastPosition.TopRight,
            });
        }
    },
})
