import Stack from '@/common/utils/Stack';
import type { AnnotationPathInterface } from '@/pages/documentSigner/utils';
import { defineStore, type Store } from 'pinia';
import type DocumentSignerStateInterface from './DocumentSignerState';
import * as PDFLib from 'pdf-lib';
import { type DocumentStoreType } from './documentStore';
import { Translator } from '@/common/i18n';

export type DocumentSignerStoreType = Store<
    'documentSignerStore',
    DocumentSignerStateInterface,
    {},
    {
        undo(): void;
        undoAll(): void;
        sign(documentStore: DocumentStoreType): Promise<void>;
        reject(documentStore: DocumentStoreType): Promise<void>;
        accept(documentStore: DocumentStoreType): Promise<void>;
        removeDocument(documentStore: DocumentStoreType): void;
        toggleDrawing(): void;
    }
>;

export const useDocumentSignerStore = defineStore('documentSignerStore', {
    state: (): DocumentSignerStateInterface => ({
        zoomValue: 0.9,
        annotationPathsStack: new Stack<AnnotationPathInterface>(),
        pdfView: null,
        viewer: null,
        currentDocument: null,
        drawMode: false,
        needResize: false,
    }),
    actions: {
        zoomIn() {
            if (this.pdfView === null) {
                return;
            }
            this.zoomValue += 0.5;
        },
        zoomOut() {
            if (this.pdfView === null) {
                return;
            }
            if (this.zoomValue <= 0.9) {
                return;
            }
            this.zoomValue -= 0.5;
        },
        undo() {
            this.annotationPathsStack.pop();
            this.needResize = true;
            window.dispatchEvent(new Event('resize'));
        },
        undoAll() {
            this.annotationPathsStack.replaceAll([]);
            this.needResize = true;
            window.dispatchEvent(new Event('resize'));
        },
        async sign(documentStore: DocumentStoreType) {
            if (this.viewer === null || this.currentDocument === null) {
                return;
            }

            if (this.annotationPathsStack.size() === 0) {
                window.alert(Translator.trans('missing_signature', 'You have not signed the document yet!', 'signer'));
                return;
            }

            const document = this.currentDocument;
            this.currentDocument = null;
            const annotationCanvases = this.viewer.querySelectorAll<HTMLCanvasElement>('.annotationLayer canvas');

            // Új PDF létrehozása az eredeti PDF fájl alapján
            // const arrayBuffer = await fetch(samplePdf).then(res => res.arrayBuffer());
            const pdfDoc = await PDFLib.PDFDocument.load(document.document);

            const newPDF = await PDFLib.PDFDocument.create();

            // Az annotációk hozzáadása az új PDF oldalakhoz
            const annotationPromises = Array.from(annotationCanvases).map(async (canvas, index) => {
                const pageNumber = index + 1;

                // Az eredeti PDF oldal másolása az új PDF dokumentumba
                const copiedPages = await newPDF.copyPages(pdfDoc, [pageNumber - 1]);
                const [copiedPage] = copiedPages;

                // Az annotációk hozzáadása az új oldalhoz
                const image = await newPDF.embedPng(canvas.toDataURL('image/png'));
                copiedPage.drawImage(image, { x: 0, y: 0, width: copiedPage.getWidth(), height: copiedPage.getHeight() });

                // Az új oldal hozzáadása az új PDF dokumentumhoz
                newPDF.addPage(copiedPage);
            });

            await Promise.all(annotationPromises);

            // Az új PDF fájl mentése
            const pdfBytes = await newPDF.save();
            const pdfEncoded = btoa(pdfBytes.reduce((data, byte) => data + String.fromCharCode(byte), ''));

            document.signedDocument = pdfEncoded;
            this.annotationPathsStack.replaceAll([]);
            this.zoomValue = 1;

            await documentStore.sign(document);

            if (documentStore.documents.length > 0) {
                this.currentDocument = documentStore.documents.shift() ?? null;
            }

            this.drawMode = false;
        },
        async reject(documentStore: DocumentStoreType) {
            if (this.currentDocument === null) {
                return;
            }

            if (!window.confirm(Translator.trans(
                'reject_confirmation',
                'Are you sure you want to reject this document?',
                'signer'
            ))) {
                return;
            }

            const document = this.currentDocument;
            this.currentDocument = null;

            await documentStore.reject(document);

            if (documentStore.documents.length > 0) {
                this.currentDocument = documentStore.documents.shift() ?? null;
            }

            this.drawMode = false;
        },
        async accept(documentStore: DocumentStoreType) {
            if (this.currentDocument === null) {
                return;
            }

            if (!window.confirm(Translator.trans(
                'accept_confirmation',
                'Are you sure you want to accept this document?',
                'signer'
            ))) {
                return;
            }

            const document = this.currentDocument;
            this.currentDocument = null;

            await documentStore.accept(document);

            if (documentStore.documents.length > 0) {
                this.currentDocument = documentStore.documents.shift() ?? null;
            }

            this.drawMode = false;
        },
        toggleDrawing() {
            this.drawMode = !this.drawMode;
        }
    }
});
