<template>
    <div class="w-full 2xl:container mx-auto p-4 flex flex-col">

        <responsive-table
            v-model:fields="listFields"
            :items="logStore.logs"
            striped
            condensed
            panel
            class="mt-4"
            :isBusy="logStore.loading"
            v-model:sortBy="sortBy"
            v-model:sortDesc="sortDesc"
            pagination
            v-model:currentPage="page"
            v-model:itemsPerPage="itemsPerPage"
            :totalItems="logStore.totalItems"
        >
            <template #cell-createdAt="{ item: log }">
                <div class="flex flex-row gap-1 justify-center items-center">
                    {{ (log as LogInterface).createdAt.slice(0,4) }}-{{ (log as LogInterface).createdAt.slice(4,6) }}-{{ (log as LogInterface).createdAt.slice(6,8) }}
                    {{ (log as LogInterface).createdAt.slice(8,10) }}:{{ (log as LogInterface).createdAt.slice(10,12) }}:{{ (log as LogInterface).createdAt.slice(12) }}
                </div>
            </template>
            <template #cell-context="{ item: log }">
                <div class="flex flex-row gap-1 justify-center items-center">
                        {{ (log as LogInterface).context }}
                </div>
            </template>
            <template #cell-actions="{ item: log }">
                <div class="flex flex-row gap-1 justify-center items-center">
                    <button type="button" @click="viewExtra((log as LogInterface).id)" class="text-blue font-bold">
                        {{Translator.trans('view', 'View', 'logs')}}
                    </button>
                </div>
            </template>
        </responsive-table>

        <modal
            :isOpen="modalIsOpen"
            @close="modalIsOpen = false"
            :title="Translator.trans('extra_info', 'Extra info', 'logs')"
        >
            <table class="table-auto bg-white mb-3 w-full rounded-md">
                <tbody>
                    <tr class="border-b-1 last:border-b-0" v-for="(modalValue, modalValueKey) in modalValues" :key="modalValueKey">
                        <th class="pl-2 border-r-1 border-gray">{{ modalValueKey }}</th>
                        <td class="pl-2 py-3" v-if="typeof modalValue === 'object'">
                            <pre>{{ JSON.stringify(modalValue, null, 2) }}</pre>
                        </td>
                        <td class="pl-2 py-3" v-else>{{ modalValue }}</td>
                    </tr>
                </tbody>
            </table>
        </modal>
    </div>
</template>

<script setup lang="ts">
import { type Ref, ref, watchEffect } from "vue";
import ResponsiveTable from "../../components/ResponsiveTable/ResponsiveTable.vue";
import type FieldDefinitionInterface from "../../components/ResponsiveTable";
import { useLogStore } from "../../stores/log/logStore";
import { type LogInterface } from "../../models/Log";
import { Translator } from "../../common/i18n";
import Modal from "../../components/Modal.vue";
import ServerError from "../../common/error/ServerError";
import {useToastStore} from "../../stores/toast/toastStore";
import { useRouter } from "vue-router";

const router = useRouter();
const logStore = useLogStore();
const toastStore = useToastStore();

const listFields: Ref<FieldDefinitionInterface[]> = ref<
    FieldDefinitionInterface[]
>([
    {
        key: "createdAt",
        label: Translator.trans('date', "Date", 'logs') ?? "Date",
        value: "createdAt",
        sortable: true,
        filterable: true,
    },
    {
        key: "levelName",
        label: Translator.trans('level', "Level", 'logs') ?? "Level",
        value: "levelName",
        filterable: true,
        sortable: true,
    },
    {
        key: "tag",
        label: Translator.trans('tag', "Tag", 'logs') ?? "Tag",
        value: "tag",
        filterable: true,
        sortable: true,
    },
    {
        key: "method",
        label: Translator.trans('method', "Method", 'logs') ?? "Method",
        value: "method",
        filterable: true,
        sortable: true,
    },
    {
        key: "clientIp",
        label: Translator.trans('ip', "IP", 'logs') ?? "IP",
        value: "clientIp",
        filterable: true,
        sortable: true,
    },
    {
        key: "uri",
        label: Translator.trans('url', "Url", 'logs') ?? "Url",
        value: "uri",
        filterable: true,
        sortable: true,
    },
    {
        key: "queryString",
        label: Translator.trans('query', "Query", 'logs') ?? "Query",
        value: "queryString",
        filterable: true,
        sortable: true,
    },
    {
        key: "message",
        label: Translator.trans('message', "Message", 'logs') ?? "Message",
        value: "message",
        filterable: true,
        sortable: true,
    },
    {
        key: "context",
        label: Translator.trans('context', "Context", 'logs') ?? "Context",
        value: "context",
        filterable: true,
        sortable: true,
    },
    {
        key: "actions",
        label: Translator.trans('extra', "Extra", 'logs') ?? "Extra",
    }
]);

const modalIsOpen = ref(false);
const modalValues = ref();

const viewExtra = (id: number) => {
    let items = logStore.logs;

    for (let itemKey in items) {
        if (id == items[itemKey].id) {
            modalValues.value = items[itemKey].extra;
        }
    }

    modalIsOpen.value = true;
};

const sortBy = ref("createdAt");
const sortDesc = ref(true);
const page = ref(1);
const itemsPerPage = ref(20);

watchEffect(() => {
    const filters: any = {};
    listFields.value.forEach(field => {
        if (field.filterable && field.filterValue) {
            filters[field.key] = field.filterValue;
        }
    });

    logStore.loadByFilters(router, {
        page: page.value.valueOf(),
        itemsPerPage: itemsPerPage.value,
        order: {
            [sortBy.value]: sortDesc.value ? 'desc' : 'asc'
        },
        ...filters
    }).catch((error: ServerError) => { toastStore.addErrorToast(error.toString())});
});
</script>
