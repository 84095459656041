import AdminLogs from "../../pages/adminLogs/AdminLogs.vue";
import AdminUserGroups from "../../pages/adminUserGroups/AdminUserGroups.vue";
import AdminUsers from "../../pages/adminUsers/AdminUsers.vue";
import AdminTranslations from "../../pages/adminTranslations/AdminTranslations.vue";

export default [
    {
        name: 'admin_logs',
        path: '/admin/logs',
        component: AdminLogs,
        meta: { requiresAuth: true, title: 'Logs' }
    },
    {
        name: 'admin_logs_api',
        path: '/api/logs',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'admin_user_groups',
        path: '/admin/user-groups',
        component: AdminUserGroups,
        meta: { requiresAuth: true, title: 'User Groups' }
    },
    {
        name: 'admin_user_groups_api',
        path: '/api/user-groups/:id(\\d+)?',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'admin_users',
        path: '/admin/users',
        component: AdminUsers,
        meta: { requiresAuth: true, title: 'Users' }
    },
    {
        name: 'admin_users_api',
        path: '/api/users/:id(\\d+)?',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'admin_translations',
        path: '/admin/translations',
        component: AdminTranslations,
        meta: { requiresAuth: true, title: 'Translations' }
    },
    {
        name: 'admin_translations_api',
        path: '/api/translations',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'admin_translations_api_write_out_translations',
        path: '/api/write-out-translations',
        redirect: '',
        meta: { requiresAuth: true }
    },
    {
        name: 'admin_configuration_api',
        path: '/api/configuration',
        redirect: '',
        meta: { requiresAuth: false }
    }
];
