import ServerError from "./ServerError";
import type ResponseData from "./ResponseData";

export default class BadRequestServerError extends ServerError {
    constructor(
        response: Response,
        responseData: ResponseData
    ) {
        super('BadRequestServerError', response, responseData);
    }
}
