import { createWebHistory, createRouter } from 'vue-router';
import qs from 'qs';
import securityRoutes from './paths/security';
import adminRoutes from './paths/admin';
import siteRoutes from './paths/site';
import documentRoutes from './paths/document';

export default createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        ...securityRoutes,
        ...adminRoutes,
        ...siteRoutes,
        ...documentRoutes
    ],
    // @ts-ignore
    parseQuery: qs.parse,
    stringifyQuery: (query) => {
        return qs.stringify(query, { encodeValuesOnly: true });
    },
});
