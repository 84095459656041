import { defineStore } from 'pinia';
import type SecurityStateInterface from './SecurityState';
import AuthenticatedUser from './AuthenticatedUser';
import fetch from '../../common/utils/fetch';
import Translator from '../../common/i18n';
import { type Router } from 'vue-router';
import UnauthorizedServerError from '@/common/error/UnauthorizedServerError';

export const useSecurityStore = defineStore('securityStore', {
    state: (): SecurityStateInterface => ({
        authenticatedUser: AuthenticatedUser.factory(),
        tokenLoading: false,
        payload: [],
    }),
    getters: {
        authenticated: (state: SecurityStateInterface): boolean => {
            if (state.authenticatedUser === null || state.authenticatedUser.exp * 1000 < Date.now()) {
                return false;
            }

            return true;
        },
    },
    actions: {
        login(router: Router, email: string, password: string): Promise<void> {
            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'security_login' }).href, {
                    method: 'POST',
                    body: JSON.stringify({ email: email, password: password }),
                })
                    .then((loginResponse) => {
                        if (typeof loginResponse.error !== 'undefined') {
                            // login failed
                            reject(
                                Translator.trans(
                                    'login_failed',
                                    'Login failed. Please, check your email and password!',
                                    'security'
                                )
                            );
                            return;
                        }

                        this.authenticatedUser = AuthenticatedUser.factory(
                            loginResponse.token,
                            loginResponse.refresh_token
                        );
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        refreshToken(router: Router) {
            this.tokenLoading = true;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'security_refresh_token' }).href, {
                    method: 'POST',
                    body: JSON.stringify({ refresh_token: AuthenticatedUser.getRefreshToken() }),
                })
                    .then((refreshResponse) => {
                        if (typeof refreshResponse.error !== 'undefined') {
                            // refresh failed
                            reject(
                                Translator.trans(
                                    'authentication_expired',
                                    'Your authentication is expired. Please login again!',
                                    'security'
                                )
                            );
                            return;
                        }

                        this.authenticatedUser = AuthenticatedUser.factory(
                            refreshResponse.token,
                            refreshResponse.refresh_token
                        );
                        this.tokenLoading = false;

                        resolve(refreshResponse);
                    })
                    .catch((e) => {
                        reject(
                            Translator.trans(
                                'authentication_expired',
                                'Your authentication is expired. Please login again!',
                                'security'
                            )
                        );
                        this.logout(router, true);
                        this.tokenLoading = false;
                    });
            });
        },

        logout(router: Router, showLogin: boolean = true) {
            return new Promise((resolve, reject) => {
                fetch(router.resolve({ name: 'security_logout' }).href)
                    .then(() => {
                        this.authenticatedUser = null;

                        if (showLogin && router.currentRoute.value.name !== 'app_login') {
                            router.push({ name: 'app_login' });
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        if (error instanceof UnauthorizedServerError) {
                            this.authenticatedUser = null;
                            router.push({ name: 'app_login' });
                            resolve(true);
                        }
                        reject(error);
                    });
            });
        },
    },
});
