import {defineStore} from "pinia";
import type TranslationStateInterface from "./TranslationState";
import Filters from "./Filters";
import languages from "../../common/i18n/_locales";
import fetch from "../../common/utils/fetch";
import {type Router} from "vue-router";
import {i18n} from "@/common/i18n";

export const useTranslationStore = defineStore('translationStore', {
    state: (): TranslationStateInterface => ({
        languages: languages,
        workingLanguages: [],
        translations: [],
        filters: new Filters(),
        totalItems: 0,
        totalPages: 0
    }),
    getters: {},
    actions: {
        loadByFilters(router: Router) {
            const filters = this.filters;

            return new Promise((resolve, reject) => {
                fetch(router.resolve({
                    name: 'admin_translations_api',
                    query: filters.createQuery()
                }).href)
                    .then(response => {
                        this.translations = response['hydra:member'];
                        this.totalItems = response['hydra:totalItems'];
                        this.totalPages = Math.ceil(this.totalItems / this.filters.itemsPerPage);

                        resolve(response['hydra:member']);
                    }).catch(err => {
                    reject(err);
                });
            });
        },
        updateTranslation(router: Router, item: any) {
            return new Promise((resolve, reject) => {
                fetch(router.resolve({name: 'admin_translations_api'}).href + '/' + item.id,
                    {method: 'PATCH', body: JSON.stringify(item)})
                    .then(response => {
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        writeOutTranslations(router: Router) {
            return new Promise((resolve, reject) => {
                fetch(router.resolve({name: 'admin_translations_api_write_out_translations'}).href,
                    {method: 'POST'})
                    .then(response => {
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        changeLocale(router: Router, locale: string) {
            return new Promise((resolve, reject) => {
                const headers = new Headers({
                    'Accept-Language': `${locale},${navigator.languages.join(',')}`
                });

                fetch(`/api/change-locale/${locale}`, { headers: headers })
                    .then(data => {
                        document.querySelector('html')?.setAttribute('lang', data.locale);
                        i18n.setLocaleMessage(data.locale, data.translations[data.locale]);
                        i18n.locale.value = data.locale;
                        resolve(data);
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        }
    },
})
