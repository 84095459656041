<template>
    <div class="min-w-max">
        <section class="pagination flex flex-col lg:flex-row justify-center lg:justify-between items-center py-3 gap-4">
            <ul class="flex items-center gap-2">
                <li v-if="hasPrev()">
                    <a
                        href="#"
                        @click.prevent="changePage(prevPage)"
                        class="flex items-center"
                    >
                        <i class="marso-icon-chevron-left"></i>
                    </a>
                </li>
                <li v-if="hasFirst()">
                    <a
                        href="#"
                        @click.prevent="changePage(1)"
                    >
                        1
                    </a>
                </li>
                <li v-if="hasFirst()">...</li>
                <li
                    v-for="page in pages"
                    :key="page"
                >
                    <a
                        href="#"
                        @click.prevent="changePage(page)"
                        :class="{'current-page': current === page}"
                    >
                        {{ page }}
                    </a>
                </li>
                <li v-if="hasLast()">...</li>
                <li v-if="hasLast()">
                    <a
                        href="#"
                        @click.prevent="changePage(totalPages)"
                    >
                        {{ totalPages }}
                    </a>
                </li>
                <li v-if="hasNext()">
                    <a
                        href="#"
                        @click.prevent="changePage(nextPage)"
                        class="flex items-center"
                    >
                        <i class="marso-icon-chevron-right"></i>
                    </a>
                </li>
            </ul>

            <div class="flex items-center">
                <div class="mr-4 whitespace-nowrap">
                    {{ Translator.trans('go_to_page', 'Go to page', 'pagination') }}
                </div>
                <div class="input-group">
                    <shop-input
                        class="w-12"
                        v-model.number="input"
                        border
                    />
                    <button
                        class="btn btn-rounded btn-secondary btn-outline"
                        @click.prevent="changePage(input)"
                    >
                        {{ Translator.trans('go', 'Go', 'pagination') }}
                        <i class="marso-icon-chevron-right"></i>
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup lang="ts">
import {computed, type Ref, ref} from "vue";
import {Translator} from '../common/i18n';
import ShopInput from "./Form/ShopInput.vue";
import {useToastStore} from "../stores/toast/toastStore";
import { ToastTypeEnum } from "@/stores/toast/ToastInterface";

const props = defineProps({
    current: {
        type: Number,
        default: 1,
    },
    total: {
        type: Number,
        default: 0,
    },
    perPage: {
        type: Number,
        default: 9,
    },
    pageRange: {
        type: Number,
        default: 2,
    },
});

const emit = defineEmits(["update:current"]);

const toastStore = useToastStore();
const input: Ref<number> = ref(1);
const pages = computed(() => {
    const pages = [];
    for (let i = rangeStart.value; i <= rangeEnd.value; i++) {
        pages.push(i);
    }
    return pages;
});
const rangeStart = computed(() => {
    const start = props.current - props.pageRange;
    return start > 0 ? start : 1;
});
const rangeEnd = computed(() => {
    const end = props.current + props.pageRange;
    return end < totalPages.value ? end : totalPages.value;
});
const totalPages = computed(() =>
    Math.ceil(props.total / props.perPage)
);
const nextPage = computed(() => props.current + 1);
const prevPage = computed(() => props.current - 1);

const hasFirst = () => rangeStart.value !== 1;
const hasLast = () => rangeEnd.value < totalPages.value;
const hasPrev = () => props.current > 1;
const hasNext = () => props.current < totalPages.value;
const changePage = (page: number) => {
    if (page > 0 && page <= totalPages.value) {
        emit("update:current", page);
        return;
    }

    toastStore.addToast({
        title: Translator.trans('page_number_out_of_range', "Page number out of range.", 'pagination'),
        type: ToastTypeEnum.Danger,
        showIcon: true,
    });
};
</script>

<style lang="scss">
.pagination {
    ul > li > a {
        @apply px-4 py-2;
    }

    .current-page {
        @apply bg-gray rounded;
    }
}
</style>
