import { type App } from "vue";
import fetch from "./fetch";

export class FetchComponent {
    private installed: boolean = false;

    /**
     * Vue.js component install method
     */
    install(app: App): void {
        if (this.installed) {
            return;
        }

        this.installed = true;

        app.provide('fetch', fetch);
    }
}

export default new FetchComponent();
