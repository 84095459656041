export default class UserRequest {
    constructor(
        public plainPassword: string | null = null,
        public email: string = '',
        public groups: Array<string> = [],
        public externalId: string = '',
        public site: string = ''
    ) {
    }
}
