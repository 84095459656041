import { defineStore } from 'pinia';
import type LogStateInterface from './LogState';
import type LogQuery from './LogQuery';
import type ShopResponseType from '../../common/utils/ShopResponseType';
import fetch from '../../common/utils/fetch';
import { type Router, type LocationQueryRaw } from 'vue-router';

export const useLogStore = defineStore('logStore', {
    state: (): LogStateInterface => ({
        loading: false,
        logs: [],
        totalItems: 0
    }),
    getters: {},
    actions: {
        loadByFilters(router: Router, query: LocationQueryRaw): Promise<ShopResponseType> {
            if (typeof query.createdAt === 'string') {
                const dateParts: string[] = query.createdAt.split('-');
                query.createdAt = dateParts.join('');
            }

            return new Promise((resolve, reject) => {
                this.loading = true;
                fetch(router.resolve({ name: 'admin_logs_api', query: query }).href)
                    .then((response) => {
                        this.logs = [];

                        if (Array.isArray(response['hydra:member'])) {
                            response['hydra:member'].forEach((element) => {
                                if (element['@type'] !== 'Log') {
                                    return;
                                }
                                this.logs.push(element);
                            });
                        }
                        this.totalItems = response['hydra:totalItems'];
                        this.loading = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.loading = false;
                        reject(error);
                    });
            });
        },
    },
});
