<template>
    <div
        :id="`dropdown_${componentId}`"
        class="relative bg-gray p-2 cursor-pointer"
        :class="{
            'bg-gray opacity-70': items?.length === 0
        }"
        ref="dropdown"
        @click="toggleDropdownItems"
    >
        <div
            class="h-full flex items-center justify-start gap-2">
            <div class="flex flex-col grow items-start justify-center content-center whitespace-nowrap overflow-hidden">
                <div v-if="disableDeselect && modelValue !== null" class="text-xs">{{placeholder}}</div>
                <div :class="{'font-bold': disableDeselect}"
                     v-html="label"></div>
            </div>
            <i v-if="modelValue !== null && !disableDeselect"
               class="marso-icon-close text-xs lg:block ml-auto"
               aria-hidden="true"
               @click="deselect"></i>
            <i class="marso-icon-chevron-down transform-gpu transition-transform text-xs"
               :class="{
                'rotate-180': dropdownItemsVisible,
                'ml-auto':  disableDeselect
            }"
               aria-hidden="true"></i>
        </div>
        <div
            :id="`dropdown_items_${componentId}`"
            v-if="dropdownItemsVisible && props.items.length"
            class="rounded-b-md z-10 bg-white min-w-full left-0 top-full"
            :class="{
                'max-h-80': items.length > 8,
                'overflow-y-scroll': items.length > 8
            }">
            <div v-for="(item, index) in refinedItems"
                 @click="selectItem(item, index)"
                 class="px-2 py-2 hover:bg-gray cursor-pointer"
                 :class="{'bg-gray': modelValue === item.value}"
                 v-html="item.text">
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    getCurrentInstance,
    nextTick,
    onMounted,
    onUnmounted,
    computed,
    type Ref,
    ref,
    type PropType,
    type ComputedRef
} from "vue";
import DropdownInputItem from "./DropdownInputItem";

const dropdownItemsVisible: Ref<boolean> = ref(false);
const componentId = getCurrentInstance()?.uid;
const dropdown: Ref<HTMLElement | null> = ref(null);
const dropdownItems: Ref<HTMLElement | null> = ref(null);
const selectedItem: Ref<DropdownInputItem | null> = ref(null);

const props = defineProps({
    placeholder: {
        type: String,
        default: ''
    },
    items: {
        type: Array as PropType<Array<String>|Array<DropdownInputItem>>,
        default: []
    },
    modelValue: {
        type: [String, Number, () => null],
        default: null
    },
    disableDeselect: {
        type: Boolean,
        default: false
    }
});

const emit= defineEmits(["update:modelValue"]);

//TODO on long term the props.items shouldn't be Array<String>, just Array<DropdownInputItem>!
const refinedItems: ComputedRef<Array<DropdownInputItem>> = computed(() => {
    return (props.items as string[] | DropdownInputItem[]).map((item: string | DropdownInputItem) => {
        if (item instanceof DropdownInputItem) {
            return item;
        }

        return new DropdownInputItem(item, item);
    })
})

const toggleDropdownItems = () => {
    dropdownItemsVisible.value = !dropdownItemsVisible.value;

    nextTick(() => {
        dropdownItems.value = document.getElementById(`dropdown_items_${componentId}`);
    })
}

const clickOutsideListener = (event: MouseEvent) => {
    if (dropdown.value !== null && !dropdown.value.contains(event.target as HTMLElement)) {
        dropdownItemsVisible.value = false;
        return;
    }
};

onMounted(() => {
    dropdown.value = document.getElementById(`dropdown_${componentId}`);
    document.addEventListener('click', clickOutsideListener);
});

onUnmounted(() => {
    document.removeEventListener('click', clickOutsideListener);
});

const selectItem = (item: DropdownInputItem, index: number) => {
    selectedItem.value = item;
    dropdownItemsVisible.value = false;
    emit("update:modelValue", item.value);
};

const deselect = () => {
    selectedItem.value = null;
    emit('update:modelValue', null);
};

const label: ComputedRef<string | undefined> = computed(() => {
    if (props.modelValue === null) {
        return props.placeholder;
    }

    let selectedItem: DropdownInputItem | undefined = refinedItems.value
        .find((item: DropdownInputItem) => item.value === String(props.modelValue));

    return selectedItem === undefined ? props.placeholder : selectedItem.text;
});
</script>
